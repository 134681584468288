<template>
    <div class="modal fade menu-popup-bx" id="Modal630181" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="menu-popup-heading">
                    {{ product.name }}</div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="menu-block-img">
                    <figure>
                        <img :src="product.image"
                            data-id="">
                    </figure>
                </div>
                <div class="recipe-content-bx">
                    <div class="menu-content-popup">
                        <div v-html="this.product_description"></div>
                        
                    </div>

                    <!-- <div class="icons modal-icon">
                        <ul>
                            <li><img src="/wp-content/themes/flexpro//images/keto.svg"> KETO
                            </li>
                            <li><img src="/wp-content/themes/flexpro/images/low-carb.svg">
                                Low Carb</li>
                            <li><img src="/wp-content/themes/flexpro/images/customer-fav.svg">
                                Customer Favorites</li>
                        </ul>
                    </div> -->

                    <div class="accordion mb-5" id="accordionExample">
                    <div class="accordion-item review-acc" style="display:none;">
                        <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Reviews
                        </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        </div>
                        </div>
                    </div>
                    <div v-if="imageUrls.length > 0" class="accordion-item nutri-acc">
                        <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo630181" aria-expanded="false"
                                aria-controls="collapseTwo630181">
                            Nutrition Facts
                        </button>
                        </h2>
                        <div id="collapseTwo630181" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <img v-for="(imageUrl, index) in imageUrls" :key="index" :src="imageUrl" alt="Product Image" class="meal-image">
                        </div>
                        </div>
                    </div>
                    </div>

                    <!-- <div class="qty-add-bx">
                        <label>QTY &nbsp;</label>
                        <form class="cart">
                            <div class="btn-group quantity" role="group" aria-label="First group">
                                <button style="pointer-events:all;" alt="630181" type="button"
                                    class="btn btn-outline-secondary minus1"><i class="fa fa-minus"></i></button>
                                <input step="1" min="0" max="" type="text" value="0" name="quantity"
                                    class="input-text qty_630181 text">
                                <button alt="630181" type="button" class="btn btn-outline-secondary plus1"><i
                                        class="fa fa-plus"></i></button>
                            </div>
                        </form>
                    </div> -->

                    <div class="protin-details">
                        <div class="row m-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 align-self-center p-0">
                                <div class="carbs-list text-center">
                                    <ul class="my-list-inline">
                                        <li>
                                            <h4>{{ cal }}</h4>
                                            <small>Calories</small>
                                        </li>
                                        <li>
                                            <h4>{{ protein }} g</h4>
                                            <small>Protein</small>
                                        </li>
                                        <li>
                                            <h4>{{ carbs }} g</h4>
                                            <small>Carbs</small>
                                        </li>
                                        <li>
                                            <h4>{{ fat }} g</h4>
                                            <small>Fat</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>

<script>
    import { globalPlans } from '../menu/Plans.vue';
    export default {
        name: 'MealPopup',
        data: function () {
        return {
        product: 'String to change',
        cal: null,
        protein: null,
        fat: null,
        carbs: null,
        imageUrls: [],
        product_description: null
        }
    },
    created (){
        this.emitter.on('popup-event', (evt) => {
            this.product = evt.eventContent;
            this.cal = evt.cal;
            this.protein = evt.protein;
            this.fat = evt.fat;
            this.carbs = evt.carbs;

            // Extract Nutrition Image URLs
            const imageRegex = /<a target="_blank" href="([^"]+)">[^<]+<\/a>/g;
            const imageMatches = this.product.description.matchAll(imageRegex);
            if (globalPlans.mealType == 'Regular') {
                const firstMatch = imageMatches.next().value;
                this.imageUrls = firstMatch ? [firstMatch[1]] : [];
            } else if (globalPlans.mealType == 'Large') {
                const matchesArray = Array.from(imageMatches);
                const secondMatch = matchesArray.length > 1 ? matchesArray[1][1] : null;
                this.imageUrls = secondMatch ? [secondMatch] : [];
            } else {
                const firstMatch = imageMatches.next().value;
                this.imageUrls = firstMatch ? [firstMatch[1]] : [];
            }

            // Remove nutritional values pattern from description
            const nutritionRegex = /Cal\s*<strong><span[^>]*>(\d+)<\/span><\/strong>\s*\|\s*Protein\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*Carbs\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*Fat\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>/;
            this.product_description = this.product.description.replace(nutritionRegex, '');
            // Remove nutritional values pattern from description
            const nutritionRegexl = /lCal\s*<strong><span[^>]*>(\d+)<\/span><\/strong>\s*\|\s*lProtein\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*lCarbs\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*lFat\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>/;
            this.product_description = this.product_description.replace(nutritionRegexl, '');
            
            // Remove Image URLs from Description
            this.product_description = this.product_description.replace(imageRegex, '');
            this.product_description = this.product_description.trim();

        })
    }

    };
</script>
<template>
    <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-5 cntget" v-on:click="emitMyProduct(product)">
        
        <div class="menu-figure-over">
            <div class="menu-list-head">
                <h4>{{ product.name }}</h4>
            </div>
            <div class="menu-figure" data-bs-toggle="modal" data-bs-target="#Modal630181" style="position: relative;">
                <figure>
                    <img :src="product.image"
                        data-id="">
                </figure>

                <!-- TBD Description Sec -->
                <div class="tbd-content-overlay">
                    <p>{{ product.description }}</p>
                </div>

            </div>
            <div class="menu-list-content">
                <div class="qty-add-bx justify-content-between px-3">
                    <div class="d-flex flex-wrap align-self-center">
                        <label>QTY &nbsp;</label>
                        <form class="cart">
                            <div class="btn-group quantity" role="group" aria-label="First group">
                                <button @click="decreaseQuantity" style="pointer-events:all;" alt="630181" type="button"
                                    class="btn btn-outline-secondary minus1"><i class="fa fa-minus"></i></button>
                                <input step="1" min="0" max="" type="text" :value="quantity" name="quantity"
                                    class="input-text qty_630181 text">
                                <button @click="increaseQuantity" alt="630181" type="button" class="btn btn-outline-secondary plus1"><i
                                        class="fa fa-plus"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="protin-details" v-html="product.description"></div> -->
                <div class="protin-details">
                    <div class="row m-0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 align-self-center p-0">
                            <div class="carbs-list text-center">
                                <ul class="my-list-inline">
                                    <li>
                                        <h3>{{ cal }}</h3>
                                        <small>Calories</small>
                                    </li>
                                    <li>
                                        <h4>{{ protein }} g</h4>
                                        <small>Protein</small>
                                    </li>
                                    <li>
                                        <h4>{{ carbs }} g</h4>
                                        <small>Carbs</small>
                                    </li>
                                    <li>
                                        <h4>{{ fat }} g</h4>
                                        <small>Fat</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import { reactive } from 'vue';
    import { globalPlans } from '../menu/Plans.vue';
    export const globalState = reactive({
        totalQuantity: 0,
        maxLimit: 5,
        minQuantity: 0,
        selectedProducts: {},
    });
    export default {
        name: 'MealInfo',
        props: ['product'],
        data() {
            return {
                cal: 0,
                protein: 0,
                carbs: 0,
                fat: 0,
                quantity: 0,
            };
        },
        mounted() {
            globalState.selectedProducts = {}
        },
        created() {
            globalState.maxLimit = globalPlans.quantitySelected;
            globalState.totalQuantity = 0;
            this.extractNutritionValues();
        },
        methods: {
            extractNutritionValues() {
                // Example product description
                let description = this.product.description;

                // Regular expression to extract Cal, Protein, Carbs, and Fat values
                if (globalPlans.mealType == 'Large') {
                    let regex = /lCal\s*<strong><span[^>]*>(\d+)<\/span><\/strong>\s*\|\s*lProtein\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*lCarbs\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*lFat\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>/;
                        // Extract values using regex
                    let match = description.match(regex);

                    if (match) {
                        this.cal = match[1];
                        this.protein = match[2];
                        this.carbs = match[3];
                        this.fat = match[4];
                    }
                } else if (globalPlans.mealType == 'Regular') {
                    let regex = /Cal\s*<strong><span[^>]*>(\d+)<\/span><\/strong>\s*\|\s*Protein\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*Carbs\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*Fat\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>/;
                        // Extract values using regex
                    let match = description.match(regex);

                    if (match) {
                        this.cal = match[1];
                        this.protein = match[2];
                        this.carbs = match[3];
                        this.fat = match[4];
                    }
                }
            },
            //  EVENT BUS To send product info to Popup
            emitMyProduct(product) {
                let description = this.product.description;
                let cal = 0;
                let protein = 0;
                let carbs = 0;
                let fat = 0;

                if (globalPlans.mealType == 'Large') {
                    // Regular expression to extract Cal, Protein, Carbs, and Fat values
                    let regex = /lCal\s*<strong><span[^>]*>(\d+)<\/span><\/strong>\s*\|\s*lProtein\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*lCarbs\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*lFat\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>/;

                    // Extract values using regex
                    let match = description.match(regex);

                    if (match) {
                        cal = match[1];
                        protein = match[2];
                        carbs = match[3];
                        fat = match[4];
                    }
                    this.emitter.emit('popup-event', {'eventContent': product, 'cal': cal, 'protein': protein, 'carbs': carbs, 'fat': fat})
                } else if (globalPlans.mealType == 'Regular') {
                    // Regular expression to extract Cal, Protein, Carbs, and Fat values
                    let regex = /Cal\s*<strong><span[^>]*>(\d+)<\/span><\/strong>\s*\|\s*Protein\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*Carbs\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>\s*\|\s*Fat\s*<strong><span[^>]*>(\d+)g<\/span><\/strong>/;

                    // Extract values using regex
                    let match = description.match(regex);

                    if (match) {
                        cal = match[1];
                        protein = match[2];
                        carbs = match[3];
                        fat = match[4];
                    }
                    this.emitter.emit('popup-event', {'eventContent': product, 'cal': cal, 'protein': protein, 'carbs': carbs, 'fat': fat})
                }
            },
            increaseQuantity() {

                if (globalState.totalQuantity < globalState.maxLimit) {
                    console.log('increase qunatity')
                    this.quantity++;
                    globalState.totalQuantity++;
                    
                    if (globalState.selectedProducts[this.product.name]) {
                        // If it exists, increment the count
                        globalState.selectedProducts[this.product.name]++;
                    } else {
                        // If it doesn't exist, add it to the object with a count of 1
                        globalState.selectedProducts[this.product.name] = 1;
                    }
                    this.emitter.emit('quantity-event-add');

                }
            },
            decreaseQuantity() {
                if (globalState.totalQuantity >= globalState.minQuantity) {
                    if (this.quantity > 0) {
                        this.quantity--;
                        globalState.totalQuantity--;
                        if (globalState.selectedProducts[this.product.name]) {
                            // Decrement the count
                            globalState.selectedProducts[this.product.name]--;
                            // If the count is 0, remove the product from the list
                            if (globalState.selectedProducts[this.product.name] === 0) {
                                delete globalState.selectedProducts[this.product.name];
                            }
                        }
                        this.emitter.emit('quantity-event-sub');
                    }
                }
            },
        }
    };
</script>
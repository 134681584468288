import axios from 'axios';

// Create the Axios instance
const api = axios.create({
  baseURL: 'https://services.leadconnectorhq.com/',
  headers: {
    'Accept': 'application/json',
    'Version': '2021-07-28'
  }
});

// Function to get the access token
export const getAccessToken = () => {
  return axios.get(`${process.env.VUE_APP_BASE_URL}/api/refresh-tokens/`)
    .then(response => {
      return response.data.access_token;
    });
};

// Function to get the Stripe Coupons
export const getCoupons = () => {
  return axios.get(`${process.env.VUE_APP_BASE_URL}/api/get-coupons`)
    .then(response => {
      return response;
    });
};

// Adding a request interceptor to append the Authorization token
api.interceptors.request.use(async config => {
  const accessToken = await getAccessToken();
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
});

// Exporting a function to get products based on locationId
export const getProducts = (locationId) => {
  return api.get(`/products/?locationId=${locationId}`);
};

// GET Product Price
export const getPrice = (locationId, productId) => {
  return api.get(`/products/${productId}/price?locationId=${locationId}`)
}
<template>
    <html lang="en-US" prefix="og: https://ogp.me/ns#">

    <head>

        <title>Meals - MICHIGAN MEAL PREP</title>

    </head>

    <body
        class="page-template page-template-tpl-vip-menu page-template-tpl-vip-menu-php page page-id-212 wp-embed-responsive theme-flexpro bt-hidevars wc-braintree-body is-light-theme singular has-main-navigation elementor-default elementor-kit-4104 e--ua-blink e--ua-chrome e--ua-webkit clickup-chrome-ext_installed"
        data-elementor-device-mode="desktop">
        <div class="vip-flex-meals-popup" style="display: none;">
            <div class="vip-flex-meals-popup-content">
                <div class="vip-flex-meals-popup-content1">
                    <span class="heading">Your Meal Box Is Ready</span>
                </div>
                <div class="vip-flex-meals-popup-content2">
                    <div class="heading"></div>
                    <div class="sub-heading"></div>
                    <div class="checkout-link-main">
                        <a href="javascript:void(0)" class="checkout-link">Express Checkout <span>→</span></a>
                    </div>
                    <div class="select-own">Click here to edit selections.</div>
                </div>
            </div>
        </div>
        <div class="vip-flex-notice-popup" style="display: none;">
            <div class="vip-flex-notice-popup-content">
                <div class="vip-flex-notice-popup-content1">
                    <span class="heading">Alert</span>
                </div>
                <div class="vip-flex-notice-popup-content2">
                    <div class="heading">All the pre selected meals will be removed!</div>
                    <div class="sub-heading">Are you sure you want to proceed?</div>
                    <div class="notice-link-main">
                        <a href="javascript:void(0)" class="notice-link">Confirm <span>→</span></a>
                    </div>
                    <div class="notice-select-own">Cancel</div>
                    <input type="hidden" name="vip_previous_selected_cate" class="vip_previous_selected_cate"
                        id="vip_previous_selected_cate" value="c_keto">
                    <input type="hidden" name="vip_selected_cate" class="vip_selected_cate" id="vip_selected_cate"
                        value="c_keto">
                </div>
            </div>
        </div>
        <div class="vip-flex-remove-popup">
            <div class="vip-flex-remove-popup-content">
                <div class="vip-flex-remove-popup-content1">
                    <span class="heading">Alert</span>
                </div>
                <div class="vip-flex-remove-popup-content2">
                    <div class="heading">All the pre selected meals will be removed!</div>
                    <div class="sub-heading">Are you sure you want to proceed?</div>
                    <div class="remove-link-main">
                        <a href="javascript:void(0)" class="remove-link">Confirm <span>→</span></a>
                    </div>
                    <div class="remove-select-own">Cancel</div>
                </div>
            </div>
        </div>
        <section class="meall for-bottom flex-vip-menu-main-class">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                        <a href="https://michiganmealprep.com/"><img width="177" height="67"
                            :src="require('@/assets/logo.jpg')" class="w-100"
                                style="max-width: 125px!important;"></a>
                    </div>
                    <div class="col-8 col-sm-9 col-md-9 col-lg-9 col-xl-10">
                        <div class="flexy-inline new-inline" style="padding: 15px 0;">
                            <div id="progress-tracker" class="progress-tracker">
                                <div class="custom-progress-line"></div>
                                <div class="step active" data-step="choose-plan">
                                    <div class="step-marker"><span class="step-number">1</span></div>
                                    <div class="step-label"><a href="/"
                                            style="color: black;text-decoration: none;">Choose Plan</a></div>
                                </div>
                                <div class="step active" data-step="select-meals">
                                    <div class="step-marker"><span class="step-number">2</span></div>
                                    <div class="step-label">Select Meals</div>
                                </div>
                                <div class="step" data-step="checkout">
                                    <div class="step-marker"><span class="step-number">3</span></div>
                                    <div class="step-label">Checkout</div>
                                </div>
                            </div>
                            <div class="final-btn">
                                <a v-if="plan_quantity === 0" href="javascript:void(0);" class="continue-final" @click="updateSubscription(subscriptionId)" style="">Update Subscription</a>
                                <a v-else href="javascript:void(0);" class="continue-progress" disabled="">Add <span class="updatecountonsidebar_remain">{{ plan_quantity }}</span> Meals to Update</a>
                            </div>
                            <pulse-loader :loading="loading_sub" :color="color" :size="size"></pulse-loader>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modalgif" style="display: none;"></div>
        <div class="body-content">
            <section class="pt-40 pb-40 sm-pt-4">
                <div class="container">
                    <div class="menu-heading text-center ml-16" style="margin-bottom: 5vh;">
                                <h1
                                style="
                                border-bottom: 3px solid #40c656;
                                display: inline-block;
                                "
                                >Protein, Carbs, Fats</h1>
                                <p
                                style="
                                    color: #9b9b9b;
                                    max-width: 700px;
                                    font-size: 16px;
                                    margin: 0 auto;
                                    font-weight: 400;
                                "
                                class="para-by-the-lb"> Explore our Signature Meals. Carefully crafted to offer a variety of flavors and balanced nutrition. Each dish is thoughtfully designed by our chefs to provide a delightful and healthy culinary experience. </p>
                            </div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 cate_flex_row">
                            <!-- Categories -->
                            <div class="shop-left-menu shop-new mb-5">
                                <div class="product-category mb-4">
                                    <h4><span class="desk-text">Categories</span><span class="mob-text">Categories</span><span class="accordion-header for-mobile-tite-only"
                                            id="headingY">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseG"
                                                aria-expanded="false" aria-controls="collapseOne"></button></span>
                                    </h4>
                                </div>

                                <div class="product-list accordion-collapse collapse show show" id="collapseI"
                                    aria-labelledby="headingX">
                                    <ul>
                                        <form id="myformchef" class="myformchef" method="post" name="myformchef">
                                        <input type="hidden" value="fat-trimmer-meal-entrees" name="cat_search_val">
                                        <li v-for="category in globalState.uniqueCategories" :key="category">
                                            <input
                                            autocomplete="off"
                                            class="form-check-input cat_starter"
                                            type="checkbox"
                                            :value="category"
                                            name="starter[]"
                                            :id="'c_' + category"
                                            @change="handleCheckboxChange($event, category)">
                                            <label class="form-check-label" :for="'c_' + category">{{ category }}</label>
                                        </li>
                                        </form>
                                    </ul>
                                </div>

                            </div>

                            <!-- Diet Filters -->

                            <div class="shop-left-menu mb-5">
                                <div class="product-category mb-4">
                                    <h4><span class="desk-text">Diet Filters</span><span class="mob-text">Diet
                                            Filters</span><span class="accordion-header for-mobile-tite-only"
                                            id="headingY">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseG"
                                                aria-expanded="false" aria-controls="collapseOne"></button></span>
                                    </h4>
                                </div>
                                <div class="accordion-collapse collapse show" id="collapseG" aria-labelledby="headingY">
                                    <form id="myformtag" class="myformtag" method="post" name="myformtag">
                                        <input type="hidden" value="fat-trimmer-meal-entrees" name="cat_search_val">
                                        <div v-for="tag in globalState.uniqueTags" :key="tag.name" class="form-check">
                                            <input 
                                            autocomplete="off" 
                                            class="form-check-input cat_tag" 
                                            type="checkbox"
                                            :value="tag.name"
                                            name="cat_tag[]" 
                                            :id="tag.name"
                                            @change="handleCheckboxChange($event, tag.name)">
                                            <label class="form-check-label" :for="tag.name">{{ tag.name }}</label>
                                            <span class="list-count-right">({{ tag.appears }})</span>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div class="shop-left-menu mb-5 clear-current-selection">
                                <a href="javascript:void(0);" class="clear-selections">Clear Current Selections →</a>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
                            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                            <div class="menu-list-details">
                                <div class="row" id="prdt">
                                    <!-- Meals and Popup -->
                                    <Meal v-for="product in products" :key="product._id" :product="product"/>
                                    <Popup />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <input type="hidden" name="box_product_id" class="box_product_id" value="16">
        <input type="hidden" name="box_number_of_week" class="box_number_of_week" value="21">
        <input type="hidden" name="box_discount_of_week" class="box_discount_of_week" value="31.71">
        <input type="hidden" name="box_discount_of_week" class="box_discount_of_week" value="31.71">
        <input type="hidden" name="is_gift_card" class="is_gift_card" value="">
        <div class="updatecountonsidebar_main meals" style="display: none;">
            <div class="updatecountonsidebar_img">
                <img width="374" height="374"
                    src="https://www.flexpromeals.com/wp-content/themes/flexpro/images/big-carticon.png">
            </div>
            <div class="updatecountonsidebar_copy">
                <div class="copy_one"><span class="updatecountonsidebar digit">21</span> out of <span
                        class="digit">21</span> meals added</div>
                <div class="copy_two" style="display: none;">add <span class="updatecountonsidebar_remain">0</span> more
                    to
                    checkout</div>
                <div class="copy_two_part" style="display: block;">
                    <a id="sideopenhrefredirect" data-checkout="true" class="sc-fzpjYC kEAxug">Proceed to Checkout</a>
                </div>
                <div class="copy_two_part" style="display: block;">
                    <span class="copy_two_part_one">Still deciding?</span><span class="copy_two_part_two">Keep editing
                        →</span>
                </div>
            </div>
        </div>
    </body>

    </html>
</template>

<style scoped>
    @import './meals.css';
</style>

<script>
    import { reactive, ref, onMounted } from 'vue';
    import { getProducts } from './api.js';
    import { globalState } from '../meals/Meal.vue';
    import Meal from './Meal.vue'
    import Popup from './Popup.vue'
    import { globalPlans } from '../menu/Plans.vue';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import { inject } from 'vue'

    export default {
        name: 'MainMenu',
        components: {
            Meal,
            Popup,
            PulseLoader
        },
        data: function () {
            return {
                quantity: 0,
                plan_quantity: 0,
                color: '#40c656',
                color1: '#40c656',
                size: '45px',
                margin: '2px',
                radius: '2px',
                subscriptionId: null,
                loading_sub: false,
                subscriptionUPdate: false,
                mealP: '',
                meal_details: {
                    Small: { price: '$1.4', originalPrice: '$1.5', type: 's', title: 'Meals / Per Week', description: 'Regular Portions.' },
                    Large: { price: '$2.00', originalPrice: '$2.10', type: 'l', title: 'Meals / Per Week', description: 'Larger Portions. 50% More Protein' }
                },
                quantities: [
                    { value: '5', label: '5', regular_price: '11.50', large_price: '14.50' },
                    { value: '10', label: '10', regular_price: '10.50', large_price: '13.50' },
                    { value: '15', label: '15', regular_price: '10.00', large_price: '13.00' },
                    { value: '20', label: '20', regular_price: '9.50', large_price: '12.50' },
                    { value: '25', label: '25', regular_price: '9.00', large_price: '12.00' }
                ]
            }
        },
        mounted() {
            this.parseQueryParams();
            this.calculatePrice();
            document.title = this.$route.meta.title || 'Default Title';
            const script = document.createElement('script');
            script.src = 'https://www.flexpromeals.com/wp-content/themes/flexpro/js/bootstrap.min.js';
            script.async = true;
            document.body.appendChild(script);
            console.log('Fetching Products in Setup...', this.subscriptionId);
        },
        setup() {
            const cryptojs = inject('cryptojs');
            const products = ref(null);
            const loading = ref(false);
            const error = ref(null);
            const response = ref(null);
            const selectedCategories = ref([]);
            const locationId = 'hrCAxvZl4GT8uGeT9GWF'
            const globalState = reactive({
                uniqueCategories: [],
                uniqueTags: [],
            });

            const fetchProducts = async () => {
                console.log('Fetching Products...');
                loading.value = true;
                try {
                    response.value = await getProducts(locationId); // Pass locationId as an argument
                    products.value = response.value.data.products.filter(product => !product.description.includes('A la carte'));

                    // Categories
                    products.value.forEach(product => {
                        const description = product.description;
                        const regex = /Categories:<\/span><\/strong><span style="font-size: 17px">([^<]+)/;
                        const matches = description.match(regex);
                        if (matches) {
                            const rawCategories = matches[1].trim().split(',');
                            const categories = Array.from(new Set(rawCategories.map(c => c.trim())));
                            categories.forEach(category => {
                            if (!globalState.uniqueCategories.includes(category)) {
                                    globalState.uniqueCategories.push(category);
                                }
                            });
                        }
                    });

                    // Tags
                    products.value.forEach(product => {
                        const description = product.description;
                        const regex = /Tags:<\/span><\/strong><span style="font-size: 17px">([^<]+)/;
                        const matches = description.match(regex);
                        if (matches) {
                            const rawTags = matches[1].trim().split(',');
                            rawTags.forEach(rawTag => {
                                const tag = rawTag.trim();
                                const existingTag = globalState.uniqueTags.find(t => t.name === tag);
                                if (existingTag) {
                                    existingTag.appears++;
                                } else {
                                    globalState.uniqueTags.push({ name: tag, appears: 1 });
                                }
                            });
                        }
                    });

                    error.value = null;
                } catch (err) {
                    console.error('Error:', err);
                    error.value = 'Failed to fetch products.';
                } finally {
                    console.log("Loading Done....")
                    loading.value = false;
                }
            };

            const handleCheckboxChange = (event, category) => {
                const isChecked = event.target.checked;
                if (isChecked) {
                    selectedCategories.value.push(category);
                } else {
                    selectedCategories.value = selectedCategories.value.filter((c) => c !== category);
                }
                handleChange(selectedCategories.value);
            };

            const handleChange = (categories) => {
                // Check if categories array is empty
                if (categories.length === 0) {
                    products.value = response.value.data.products.filter(product => !product.description.includes('A la carte'));
                } else {
                    // Filter products by categories
                    products.value = products.value = response.value.data.products.filter(product => !product.description.includes('A la carte'));
                    products.value = products.value.filter((product) => {
                        // Check if any of the selected categories is in the product description
                        return categories.some((category) => product.description.includes(category));
                    });
                }
            };

            onMounted(fetchProducts);
            return { products, loading, error, globalState, selectedCategories, cryptojs, handleCheckboxChange };
        },
        created(){
            globalState.selectedProducts = {}
            this.plan_quantity = globalPlans.quantitySelected;
            this.emitter.on('quantity-event-add', () => {
                this.plan_quantity = this.plan_quantity - 1;
            }),
            this.emitter.on('quantity-event-sub', () => {
                this.plan_quantity = this.plan_quantity + 1;
            })

        },
        
        methods: {
            // Check for subscription ID in queryparams
            parseQueryParams() {
                try {
                    // console.log('Before Decryption');
                    const encodedSubscriptionId = this.$route.query.subscriptionId;
                    // console.log('Encoded Subscription ID:', encodedSubscriptionId);

                    const decodedSubscriptionId = decodeURIComponent(encodedSubscriptionId);
                    // console.log('Decoded Subscription ID:', decodedSubscriptionId);

                    const subscriptionId = this.cryptojs.AES.decrypt(decodedSubscriptionId, "mmp_order_sub").toString(this.cryptojs.enc.Utf8);
                    // console.log('After Decryption:', subscriptionId);

                    if (subscriptionId) {
                        const matches = subscriptionId.match(/(sub_\w+)(\d{2})([a-zA-Z])/);
                        if (matches) {
                            this.subscriptionId = matches[1];
                            this.plan_quantity = matches[2] == '05' ? '5' : matches[2];
                            this.mealType = matches[3];
                        }
                    }

                    // console.log('Subscription ID:', this.subscriptionId);
                    // console.log('Plan Quantity:', this.plan_quantity);
                    // console.log('Meal Type:', this.mealType);

                    globalPlans.quantitySelected = this.plan_quantity;
                } catch (error) {
                    console.error('Decryption Error:', error);
                }
            },

            // Calculate Meal Price
            calculatePrice() {
                const quantity = this.quantities.find(q => q.value === this.plan_quantity);
                if (quantity) {
                    if (this.mealType === 'l') {
                    this.mealP = quantity.large_price;
                    } else if (this.mealType === 's') {
                    this.mealP = quantity.regular_price;
                    }
                }
                console.log('Price:', this.mealP);
            },

            gotoCheckout() {
                this.$router.push({ path: '/checkout' })
            },

            // POST Update Subscripiton Information to API
            sendOrderInformation(orderDetails) {
                console.log('Sending Information to API')
                const orderDetailsJson = JSON.stringify(orderDetails);
                const order_url = 'https://backend.leadconnectorhq.com/hooks/hrCAxvZl4GT8uGeT9GWF/webhook-trigger/d6956f8e-347d-4ee3-b647-bff741c02b3a'

                fetch(order_url, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: orderDetailsJson,
                })
                .then(response => response.json())
                .then(data => {
                    console.log(data.status);
                })
                .catch(error => {
                    console.error('Error submitting order:', error);
                });
            },

            async updateSubscription(subscriptionID) {
                this.loading_sub = true
                const products = [
                        // additionalProduct,
                        ...Object.entries(globalState.selectedProducts).map(([name, quantity]) => {
                            return { name: name, quantity: quantity, price: Math.round(this.mealP * 100) };
                        })
                    ];
                console.log('selected products.?.? ', products)
                try {
                    const response = await fetch(`${process.env.VUE_APP_BASE_URL}/api/update-stripe-subscription`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        subscriptionId: subscriptionID,
                        products: products
                    })
                    });

                    const result = await response.json();
                    if (response.ok) {
                        this.loading_sub = false
                        this.subscriptionUPdate = true
                        console.log('Subscription updated successfully:', result);
                        const orderDetails = {
                            orders: products,
                            subscriptionID: subscriptionID
                        }
                        this.sendOrderInformation(orderDetails)

                        //  Go to Payment success page on subscription Updated
                        this.$router.push({ 
                            name: 'payment-success', 
                            params: { 
                                isSubUpdate: true,
                                orders: JSON.stringify(products),
                                subscriptionID: subscriptionID
                            } 
                        });
                    } else {
                        this.loading_sub = false
                        this.$router.push({ path: '/' })
                        console.error('Error updating subscription:', result);
                    }
                } catch (error) {
                        this.loading_sub = false
                        this.$router.push({ path: '/' })
                        console.error('Error updating subscription:', error);
                }
            }
            
        }
    };
</script>
<template>
  <body>
    <div class="flex flex-col min-h-screen container" >
      <main class="flex flex-col items-center justify-center flex-grow text-center p-4 md:p-6">
        <svg v-if="isSuccess"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="h-16 w-16 text-green-500"
        >
          <circle  cx="12" cy="12" r="10"></circle>
          <path d="m9 12 2 2 4-4"></path>
        </svg>
        <h1 v-if="isSuccess && !isSubUpdate" class="mt-4 text-2xl font-semibold">Payment Successful</h1>
        <h1 v-if="isSuccess && isSubUpdate" class="mt-4 text-2xl font-semibold"> Your subscription has been updated</h1>
        <p v-if="isSuccess" class="mt-2 text-gray-500 dark:text-gray-400">Thank you for your purchase!</p>
        <div v-if="isSuccess" class="mt-6 border rounded-lg p-4 w-full max-w-md">
          <span>Your Order Details:</span>
          <div class="flex justify-between text-sm">
            <br>
            <table>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th v-if="isSuccess && isSubUpdate">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                  <td>{{ order.name }}</td>
                  <td>{{ order.quantity }}</td>
                  <td v-if="isSuccess && isSubUpdate">$ {{ (order.price/100)*order.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm" style="margin-bottom: 8px;">
            <span>Payment Type:</span>
            <span class="font-medium">{{ paymentType }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Sub Total:</span>
            <span class="font-medium">${{ parseFloat(this.subTotal).toFixed(2) }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Sales tax:</span>
            <span class="font-medium">${{ parseFloat(this.tax).toFixed(2) }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Total including tax:</span>
            <span class="font-medium">${{ parseFloat(this.totalWithTax).toFixed(2) }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Delivery Charges:</span>
            <span class="font-medium">${{ parseFloat(this.deliveryCharges).toFixed(2) }}</span>
          </div>
          <!-- <div v-if="couponCode" class="flex justify-between text-sm mt-2">
            <span>Grand Total:</span>
            <span class="font-medium">${{ parseFloat(amount).toFixed(2) + parseFloat(discountAmount).toFixed(2) }}</span>
          </div> -->
          <div v-if="couponCode" class="flex justify-between text-sm mt-2">
            <span>Discount Amount:</span>
            <span class="font-medium">${{ parseFloat(this.discountAmount).toFixed(2) }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Grand Total:</span>
            <span class="font-medium">${{ parseFloat(amount).toFixed(2) }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Customer Name:</span>
            <span class="font-medium">{{ customerName }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Address: </span>
            <span class="font-medium"> {{ deliveryAddress }}</span>
          </div>
          <div v-if="isSuccess && isSubUpdate" class="flex justify-between text-sm mt-2">
            <span>Subscription ID:</span>
            <span class="font-medium">{{ subscriptionID }}</span>
          </div>
          <div v-if="isSuccess && !isSubUpdate" class="flex justify-between text-sm mt-2">
            <span style="text-align: left;">Order Date &amp; Time: </span>
            <span class="font-medium">{{ formattedDate }}</span>
          </div>
          <div v-if="couponCode" class="flex justify-between text-sm mt-2">
            <span>Coupon &amp; Code:</span>
            <span class="font-medium">{{ couponCode }}</span>
          </div>
        </div>
        <a
          class="mt-6 inline-flex items-center justify-center h-10 px-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          href="/"
        >
          Return to Homepage
        </a>
      </main>
    </div>
  </body>
  </template>
  
  <style lang="css" src="./success.css" scoped></style>
  
  <script>
  export default {
    name: 'PaymentSuccess',
    data() {
      return {
        currentDateTime: new Date(),
        isSuccess: true,
        orders: {} // Initialize orders in data
      };
    },
    computed: {
      subTotal() {
        return this.$route.params.subTotal;
      },
      totalWithTax() {
        const subTotal = parseFloat(this.$route.params.subTotal);
        const taxAmount = parseFloat(this.$route.params.taxAmount);
        console.log(subTotal + ' :: ' + taxAmount);
        return subTotal + taxAmount;
      },
      tax() {
        return this.$route.params.taxAmount;
      },
      deliveryCharges() {
        return this.$route.params.deliveryCharges;
      },
      amount() {
        return this.$route.params.amount;
      },
      customerName() {
        return this.$route.params.customerName;
      },
      deliveryAddress() {
        return this.$route.params.deliveryAddress;
      },
      formattedDate() {
        return this.formatDate(this.currentDateTime);
      },
      paymentType() {
        return this.$route.params.paymentType;
      },

      discountAmount() {
        return this.$route.params.discountAmount;
      },
      couponCode() {
        return this.$route.params.couponCode;
      },
      isSubUpdate() {
        return this.$route.params.isSubUpdate;
      },
      subscriptionID() {
        return this.$route.params.subscriptionID;
      }
      
    },
    methods: {
      formatDate(date) {
        const options = { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit',
          second: '2-digit'
        };
        return date.toLocaleDateString('en-US', options);
      },
      fetchOrders() {
        try {
          this.orders = JSON.parse(this.$route.params.orders); // Deserialize orders
        } catch (error) {
          this.orders = {}; // Set orders to an empty object if parsing fails
          this.isSuccess = false;
          alert('Order Information not correct. Please check your order details 1');
        }
      }
    },
    watch: {
      orders(newOrders) {
        if (Object.keys(newOrders).length === 0 && this.$route.params.orders) {
          this.isSuccess = false;
          alert('Order Information not correct. Please check your order details');
        }
      }
    },
    mounted() {
      this.currentDateTime = new Date();
      this.fetchOrders(); // Fetch orders when the component is mounted
    }
  };
  </script>
  
<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=M8kjp3';
    script.async = true;
    document.body.appendChild(script);
  }
}
</script>
<template>
    <div class="elementor-element elementor-element-286d3f1 e-con-full e-transform e-flex e-con" data-id="286d3f1">
        <div class="elementor-element elementor-element-a935f43 e-con-boxed e-flex e-con" data-id="a935f43"
            data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}">
            <div class="e-con-inner">
                <div class="elementor-element elementor-element-f20308e e-con-full e-flex e-con" data-id="f20308e"
                    data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                    <div class="elementor-element elementor-element-0c165ea elementor-widget-mobile__width-initial elementor-widget__width-initial elementor-widget elementor-widget-image"
                        data-id="0c165ea" data-element_type="widget" data-widget_type="image.default">
                        <div class="elementor-widget-container">
                            <a href="https://michiganmealprep.com/">
                                        <img decoding="async" width="177" height="67"
                                            :src="require('@/assets/logo.jpg')"
                                            class="attachment-full size-full wp-image-1269871" alt="1"
                                            style="width:100%;height:37.85%;max-width:177px" title="Menu">
                                    </a>
                        </div>
                    </div>
                </div>
                <div class="elementor-element elementor-element-d6eefe5 e-con-full e-flex e-con" data-id="d6eefe5"
                    data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                    <div class="elementor-element elementor-element-4a94a72 elementor-widget-mobile__width-inherit elementor-widget elementor-widget-html"
                        data-id="4a94a72" data-element_type="widget" data-widget_type="html.default">
                        <div class="elementor-widget-container">
                            <!DOCTYPE html>
                            <html>

                            <head>

                            </head>

                            <body>
                                <div id="progress-tracker" class="progress-tracker" style="width: 100% !important;">
                                    <div class="custom-progress-line"></div>
                                    <div class="active step" data-step="choose-plan">
                                        <div class="step-marker"><span class="step-number">1</span></div>
                                        <div class="step-label">Choose Plan</div>
                                    </div>
                                    <div class="step" data-step="select-meals">
                                        <div class="step-marker"><span class="step-number">2</span></div>
                                        <div class="step-label">Select Meals</div>
                                    </div>
                                    <div class="step" data-step="checkout">
                                        <div class="step-marker"><span class="step-number">3</span></div>
                                        <div class="step-label">Checkout</div>
                                    </div>
                                </div>
                            </body>

                            </html>
                        </div>
                    </div>
                </div>
                <div class="elementor-element elementor-element-5219299 e-con-full elementor-hidden-tablet elementor-hidden-mobile e-flex e-con"
                    data-id="5219299" data-element_type="container"
                    data-settings="{&quot;content_width&quot;:&quot;full&quot;}">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MenuTop',
};
</script>

import { createRouter, createWebHistory } from 'vue-router'
import Menu from '../components/menu/Menu.vue'
import Meals from '../components/meals/Meals.vue'
import Checkout from '../components/checkout/Checkout.vue'
import MealPrepBox from '@/components/meals/MealPrepBox.vue'
import PaymentSuccess from '@/components/payment/PaymentSuccess.vue'
// import SubscriptionDetails from '@/components/subscription/SubscriptionDetails.vue'
import SubMeals from '@/components/meals/SubMeals.vue'
import '@fortawesome/fontawesome-free/css/all.css';
// import CancelSubscription from '@/components/payment/CancelSubscription'

const routes = [
  { path: '/', component: Menu, meta: { title: 'Signature Meals Ordering | Michigan Meal Prep' } },
  { path: '/checkout', component: Checkout, meta: { title: 'Meals Ordering Checkout | Michigan Meal Prep' } },
  { path: '/meals', component: Meals, meta: { title: 'Signature Meals Ordering | Michigan Meal Prep' } },
  { path: '/alacarte', component: MealPrepBox, meta: { title: 'À LA CARTE Meals Ordering | Michigan Meal Prep' } },
  { path: '/payment-success', name: 'payment-success', component: PaymentSuccess },
  // { path: '/subscriptions', name: 'subscriptions', component: SubscriptionDetails },
  { path: '/subscription', name: 'subscription', component: SubMeals },
  // { path: '/cancel', name: 'cancel_subscription', component: CancelSubscription },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
<template>
    <html lang="en-US" prefix="og: https://ogp.me/ns#">

    <head>

        <title>Checkout - Michigan Meal Prep</title>
        <link rel="stylesheet" href="checkout.css">
        <meta name="viewport" content="width=device-width, initial-scale=1"/>

    </head>

    <body
        class="page-template-default page page-id-8 wp-embed-responsive theme-flexpro bt-hidevars wc-braintree-body woocommerce-checkout woocommerce-page woocommerce-js is-light-theme singular has-main-navigation elementor-default elementor-kit-4104 elementor-page elementor-page-8 e--ua-blink e--ua-chrome e--ua-webkit"
        data-elementor-device-mode="desktop">
        <header class="header-section">
            <section class="header-choose-section">
                <div class="container">
                    <div class="choose-middle-head text-center">
                        <a href="">
                            <div class="shipping_timer">
                                <p id="deliveryInfo_master"></p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <section class="navbar-navigation-section">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <div class="container">
                        <a class="navbar-brand" title="Michigan Meal Prep" href="/">
                            <figure>
                                <a href="https://michiganmealprep.com/">
                                    <img decoding="async" width="177" height="67" :src="require('@/assets/logo.jpg')"
                                        class="attachment-full size-full wp-image-1269871" alt="1"
                                        style="width:100%;height:37.85%;max-width:130px" title="Menu">
                                </a>
                            </figure>
                        </a>
                        <a title="Menus" href="/"><button id="getStarted" type="button" class="btn btn-light">GET
                                STARTED »</button></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li class="nav-item"><a class="nav-link active" href="">Meal boxes &amp; menu</a></li>
                                <li class="nav-item"><a class="nav-link" href="">About
                                    </a></li>
                                <li class="nav-item"><a class="nav-link" href="">Meal
                                        Prep Box</a></li>
                                <li class="nav-item"><a class="nav-link" href="">Power Bakery</a></li>
                                <li class="nav-item"><a class="nav-link" href="">Help</a></li>
                                <li class="nav-item">
                                    <a class="nav-link" href="" id="navbarDropdown" role="button" aria-expanded="false">
                                        <span class="login_myaccount_text">Sign In</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
        </header>
        <article id="post-8" class="post-8 page type-page status-publish hentry entry">
            <div class="page-conatiner pb-40">
                <div class="container">
                    <header class="entry-header alignwide">
                        <h1 class="entry-title">Checkout</h1>
                    </header>
                    <div class="entry-content">
                        <div class="woocommerce">
                            <div class="woocommerce-notices-wrapper"></div>
                            <div class="review_go_back">
                                <a class="review_go_back_a" href="javascript:void(0)">&lt;&lt;&lt; Go Back</a>
                            </div>
                            <div class="checkout-top-items">
                                <div class="flex_custom_coupon_login custom_login_btn">
                                    <a
                                    style="
                                        color: #40c656;
                                        text-decoration: none;
                                        font-weight: 700;
                                    "
                                    href="https://billing.stripe.com/p/login/4gw3dF1Kk6DK29WcMM">Returning Customer? Click Here</a>
                                </div>
                                <div class="woocommerce-form-login-toggle" style="display:none;">
                                    <div class="woocommerce-info">
                                        Returning customer? <a href="#" class="showlogin">Click here to login</a> </div>
                                </div>
                                <form class="woocommerce-form woocommerce-form-login login" method="post"
                                    style="display:none;" data-hs-cf-bound="true">
                                    <p>If you have shopped with us before, please enter your details below. If you are a new
                                        customer, please proceed to the Billing section.</p>
                                    <p class="form-row form-row-first">
                                        <label for="username">Username or email&nbsp;<span class="required">*</span></label>
                                        <input type="text" class="input-text" name="username" id="username"
                                            autocomplete="username">
                                    </p>
                                    <p class="form-row form-row-last">
                                        <label for="password">Password&nbsp;<span class="required">*</span></label>
                                        <input class="input-text" type="password" name="password" id="password"
                                            autocomplete="current-password">
                                    </p>
                                    <div class="clear"></div>
                                    <p class="form-row">
                                        <label
                                            class="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme">
                                            <input class="woocommerce-form__input woocommerce-form__input-checkbox"
                                                name="rememberme" type="checkbox" id="rememberme" value="forever">
                                            <span>Remember me</span>
                                        </label>
                                        <input type="hidden" id="woocommerce-login-nonce" name="woocommerce-login-nonce"
                                            value="ae7d36fb39"><input type="hidden" name="_wp_http_referer"
                                            value="/checkout/">
                                        <input type="hidden" name="redirect" value="">
                                        <button type="submit"
                                            class="woocommerce-button button woocommerce-form-login__submit" name="login"
                                            value="Login">Login</button>
                                    </p>
                                    <p class="lost_password">
                                        <a href="">Lost your
                                            password?</a>
                                    </p>
                                    <div class="clear"></div>
                                    <input type="hidden" name="gaclientid" value="1683418003.1710877823">
                                </form>
                                <div class="flex_custom_coupon custom_coupon" @click="toggleCoupon">
                                    <span>Enter Discount Code Here</span>
                                </div>
                                <div class="flex_edit_cart_button">
                                    <span>Edit Cart</span>
                                </div>
                            </div>
                            <div class="paypal-transaction-success" v-if="paypalSuccess">
                                <div class="woocommerce-success">
                                    "Transaction Completed by {{ payer }}"
                                </div>
                            </div>
                            <div class="stripe-transaction-success" v-if="stripeSuccess">
                                <div class="woocommerce-success">
                                    "Transaction Successfull"
                                </div>
                            </div>
                            <div class="woocommerce-form-coupon-toggle" v-if="showCouponMessage">
                                <div class="woocommerce-info" v-if="!couponFound && !showCouponError">
                                    Coupon "{{ couponCode }}" does not exist!
                                </div>
                                <div class="woocommerce-success" v-if="couponFound && !showCouponError">
                                    Coupon "{{ couponCode }}" exists! Discount applied.
                                </div>
                            </div>

                            <form class="checkout_coupon woocommerce-form-coupon" method="post"  v-if="showCoupon"
                                data-hs-cf-bound="true">
                                <p>If you have a coupon code, please apply it below.</p>
                                <p class="form-row form-row-first">
                                    <input type="text" name="coupon_code" class="input-text" placeholder="Coupon code"
                                        id="coupon_code" value="">
                                </p>
                                <p class="form-row form-row-last">
                                    <!-- <button type="submit" class="button" name="apply_coupon" value="Apply coupon" @click="fetchCoupons">Apply coupon</button> -->
                                    <button type="button" class="button" name="apply_coupon" value="Apply coupon" @click.prevent="fetchCoupons">Apply coupon</button>

                                </p>
                                <div class="clear"></div>
                                <input type="hidden" name="gaclientid" value="1683418003.1710877823">
                            </form>
                            <div class="woocommerce-notices-wrapper"></div>
                            <form name="checkout" method="post" @submit.prevent="submitPayment" class="checkout woocommerce-checkout" action=""
                                enctype="multipart/form-data">
                                <p id="wc-stripe-payment-request-button-separator"
                                    style="margin-top:1.5em;text-align:center;display:none;">— OR —</p>
                                <p id="wc-stripe-payment-request-button-separator"
                                    style="margin-top:1.5em;text-align:center;display:none;">— OR —</p>
                                <div class="col2-set checkout-columns">
                                    <div class="col-1" id="customer_details">
                                        <div class="woocommerce-billing-fields">
                                            <div class="woocommerce-billing-fields__field-wrapper">
                                                <h3 style="color:#40c656">STEP 1</h3>
                                                <h3>Billing details</h3>
                                                <p class="form-row form-row-first validate-required validate-required"
                                                    id="billing_first_name_field" data-priority="10"><label
                                                        for="billing_first_name" class="">First name&nbsp;<abbr
                                                            class="required" title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper">
                                                        <input type="text"
                                                            class="input-text "
                                                            name="billing_first_name"
                                                            id="billing_first_name"
                                                            v-model="billingFirstName"
                                                            placeholder="" value=""
                                                            autocomplete="given-name">
                                                        <div data-lastpass-icon-root=""
                                                            style="position: relative !important; height: 0px !important; width: 0px !important; float: left !important;">
                                                        </div>
                                                    </span>
                                                    <span v-if="formSubmitted && !billingFirstName" class="error-message">* First name is required.</span>
                                                    </p>
                                                <p class="form-row form-row-last validate-required validate-required"
                                                    id="billing_last_name_field" data-priority="20"><label
                                                        for="billing_last_name" class="">Last name&nbsp;<abbr
                                                            class="required" title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper">
                                                        <input type="text"
                                                            class="input-text "
                                                            name="billing_last_name"
                                                            v-model="billingLastName"
                                                            id="billing_last_name" placeholder="" value=""
                                                            autocomplete="family-name"></span>
                                                            <span v-if="formSubmitted && !billingLastName" class="error-message">* Last name is required.</span>
                                                        </p>
                                                <p class="form-row form-row-wide validate-required validate-required validate-email"
                                                    id="billing_email_field" data-priority="25"><label
                                                        for="billing_email" class="">Email address&nbsp;<abbr
                                                            class="required" title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper"><input type="text"
                                                            class="input-text " 
                                                            name="billing_email"
                                                            id="billing_email"
                                                            v-model="billingEmail"
                                                            placeholder="" value=""
                                                            autocomplete="email username"></span>
                                                            <span v-if="formSubmitted && !billingEmail" class="error-message">* Email is required.</span>
                                                </p>
                                                <p class="form-row form-row-wide validate-required validate-required validate-phone"
                                                    id="billing_phone_field" data-priority="26"><label
                                                        for="billing_phone" class="">Phone&nbsp;<abbr class="required"
                                                            title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper"><input type="text"
                                                            class="input-text "
                                                            v-model="billingPhone"
                                                            name="billing_phone" id="billing_phone"
                                                            placeholder="" value="" autocomplete="tel"></span>
                                                            <span v-if="formSubmitted && !billingPhone" class="error-message">* Phone number is required.</span>
                                                        </p>
                                                <p class="form-row form-row-wide" id="billing_company_field"
                                                    data-priority="30"><label for="billing_company" class="">Company
                                                        name&nbsp;<span class="optional">(optional)</span></label><span
                                                        class="woocommerce-input-wrapper"><input type="text"
                                                            class="input-text "
                                                            name="billing_company"
                                                            v-model="billingCompany"
                                                            id="billing_company" placeholder="" value=""
                                                            autocomplete="organization"></span></p>

                                                <p class="form-row address-field update_totals_on_change form-row-wide validate-required"
                                                    id="billing_country_field" data-priority="40">
                                                    <label for="billing_country">Country / Region&nbsp;<abbr
                                                            class="required" title="required">*</abbr></label>
                                                    <span class="woocommerce-input-wrapper">
                                                        <select name="billing_country" id="billing_country"
                                                            class="country_to_state country_select"
                                                            autocomplete="country"
                                                            data-placeholder="Select a country / region…"
                                                            data-label="Country / Region" tabindex="-1"
                                                            aria-hidden="true" v-model="selectedCountry">
                                                            <option value="">Select a country / region…</option>
                                                            <option v-for="country in countries"
                                                                :value="country.abbreviation"
                                                                :key="country.abbreviation">
                                                                {{ country.name }}
                                                            </option>
                                                        </select>

                                                        <noscript><button type="submit"
                                                                name="woocommerce_checkout_update_totals"
                                                                value="Update country / region">Update country /
                                                                region</button></noscript>
                                                    </span>
                                                    <span v-if="formSubmitted && !selectedCountry" class="error-message">* Country is required.</span>
                                                </p>

                                                <p class="form-row address-field form-row-wide validate-required"
                                                    id="billing_address_1_field" data-priority="50"><label
                                                        for="billing_address_1" class="">Street address <abbr
                                                            class="required" title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper"><input type="text"
                                                            class="input-text " name="billing_address_1"
                                                            v-model="billingAddress"
                                                            id="billing_address_1"
                                                            placeholder="House number and street name" value=""
                                                            autocomplete="address-line1"></span>
                                                            <span v-if="formSubmitted && !billingAddress" class="error-message">* Address is required.</span>
                                                        </p>
                                                <p class="form-row address-field form-row-wide"
                                                    id="billing_address_2_field" data-priority="60"><label
                                                        for="billing_address_2" class="screen-reader-text">Apartment,
                                                        suite, unit, etc.</label><span
                                                        class="woocommerce-input-wrapper"><input type="text"
                                                            class="input-text " name="billing_address_2"
                                                            v-model="billingAddress2"
                                                            id="billing_address_2"
                                                            placeholder="Apartment, suite, unit, etc. (optional)"
                                                            value="" autocomplete="address-line2"></span></p>
                                                <p class="form-row address-field form-row-wide validate-required"
                                                    id="billing_city_field" data-priority="70"
                                                    data-o_class="form-row address-field form-row-wide validate-required">
                                                    <label for="billing_city" class="">Town / City <abbr
                                                            class="required" title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper"><input type="text"
                                                            class="input-text " name="billing_city" id="billing_city"
                                                            v-model="billingCity"
                                                            placeholder="" value=""
                                                            autocomplete="address-level2"></span>
                                                            <span v-if="formSubmitted && !billingCity" class="error-message">* City is required.</span>
                                                </p>
                                                <p class="form-row address-field form-row-wide validate-required validate-state woocommerce-validated"
                                                    id="billing_state_field" data-priority="80"
                                                    data-o_class="form-row address-field form-row-wide validate-required validate-state">
                                                    <label for="billing_state" class="">State <abbr class="required"
                                                            title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper">
                                                        <select name="billing_state" id="billing_state"
                                                            class="state_select " autocomplete="address-level1"
                                                            data-placeholder="Select an option…" data-input-classes=""
                                                            v-model="billingState"
                                                            data-label="State" tabindex="-1" aria-hidden="true">
                                                            <option value="">Select State…</option>
                                                            <option v-for="state in states" :value="state.abbreviation"
                                                                :key="state.abbreviation">
                                                                {{ state.name }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                    <span v-if="formSubmitted && !billingState" class="error-message">* State is required.</span>
                                                </p>
                                                <p class="form-row form-row-wide address-field validate-required validate-postcode"
                                                    id="shipping_postcode_field" data-priority="90"
                                                    data-o_class="form-row form-row-wide address-field validate-required validate-postcode">
                                                    <label for="shipping_postcode" class="">ZIP Code <abbr
                                                            class="required" title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper">
                                                        <input type="text"
                                                            class="input-text " name="shipping_postcode"
                                                            id="shipping_postcode"
                                                            placeholder="" 
                                                            value=""
                                                            v-model="billingZipCode"
                                                            autocomplete="postal-code">
                                                        </span>
                                                        <span v-if="formSubmitted && !billingZipCode" class="error-message">* ZipCode is required.</span>
                                                </p>
                                                
                                                <!-- Delivery or Pickup -->
                                                <div>
                                                    <h3 style="color:#40c656; margin-top: 4vh;">STEP 2 SELECT DELIVERY OR PICK-UP</h3>
                                                    <div class="radio-group">
                                                        <input style="margin-right: 3px;" type="radio" id="pickup" value="pickup" v-model="selectedOption" @change="optionChanged">
                                                        <label style="margin: 5px;" for="pickup">Choose pickup location</label>
                                                        <input style="margin-right: 3px;" type="radio" id="delivery" value="delivery" v-model="selectedOption" @change="optionChanged">
                                                        <label style="margin-right: 15px;" for="delivery">Delivery option</label>
                                                    </div>
                                                </div>

                                                <!-- <p v-if="selectedOption === 'delivery'" class="form-row address-field update_totals_on_change form-row-wide validate-required"
                                                    id="billing_zip_field" data-priority="40">
                                                    <label for="billing_zip">Zip Code&nbsp;<abbr
                                                            class="required" title="required">*</abbr></label>
                                                    <span class="woocommerce-input-wrapper">
                                                        <select name="billing_zip" id="billing_zip"
                                                            data-placeholder="Select a zipcode…"
                                                            data-label="ZipCode" tabindex="-1"
                                                            aria-hidden="true" v-model="billingPostCode">
                                                            <option value="">Select Zip Code..</option>
                                                            <option v-for="zipcode in zipcodes"
                                                                :value="zipcode"
                                                                :key="zipcode">
                                                                {{ zipcode }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </p> -->

                                                <p v-if="selectedOption === 'delivery'" class="form-row address-field update_totals_on_change form-row-wide validate-required"
                                                    id="billing_zip_field" data-priority="40">
                                                        <label for="billing_zip">Zip Code&nbsp;<abbr class="required" title="required">*</abbr></label>
                                                        <span class="woocommerce-input-wrapper">
                                                            <div class="searchable-select">
                                                                <input type="text" v-model="searchQuery" placeholder="Search Zip Code..." @input="filterZipCodes">
                                                                <div class="dropdown" v-if="searchQuery.length > 0 && showDropdown">
                                                                    <ul>
                                                                        <li v-for="zipcode in filteredZipcodes" :key="zipcode" @click="selectZipCode(zipcode)">
                                                                            {{ zipcode }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <label v-if="isZipcodeFound === false">Zip code not found. Please choose a pickup location.</label>
                                                                <label v-if="isZipcodeFound === true && this.filteredZipcodes.length > 0 && showDropdown === false">yes we do deliver to this location so please enter shipping address if different then billing address.</label>
                                                            </div>
                                                        </span>
                                                        <span v-if="formSubmitted && !searchQuery" class="error-message">* Zip Code is required.</span>
                                                    </p>

                                                <p v-if="selectedOption === 'pickup' || isZipcodeFound === false" class="form-row address-field update_totals_on_change form-row-wide validate-required"
                                                    id="billing_pickup_field" data-priority="40">
                                                    <label for="billing_pcikup">Pickup Locations&nbsp;<abbr
                                                            class="required" title="required">*</abbr></label>
                                                    <span class="woocommerce-input-wrapper">
                                                        <select name="billing_pickup" id="billing_pickup"
                                                            data-placeholder="Select a Pickup.."
                                                            data-label="Pickup" tabindex="-1"
                                                            aria-hidden="true" v-model="pickupLocation">
                                                            <option value="">Select a Pickup Location..</option>
                                                            <option v-for="location in pickupLocations"
                                                                :value="location"
                                                                :key="location">
                                                                {{ location }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                    <span v-if="formSubmitted && !pickupLocation" class="error-message">* Pickup Location is required.</span>
                                                </p>

                                                <!-- Ship to a different address -->
                                                <div v-if="selectedOption === 'delivery' && isZipcodeFound === true" class="woocommerce-shipping-fields">
                                                    <h3 id="ship-to-different-address">
                                                        <label
                                                            class="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
                                                            <input 
                                                                id="ship-to-different-address-checkbox"
                                                                class="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                                                                type="checkbox" 
                                                                name="ship_to_different_address" 
                                                                value="1"
                                                                v-model="shipToDifferentAddress"
                                                            >
                                                            <span>  Ship to a different address?</span>
                                                        </label>
                                                    </h3>
                                                    <div class="shipping_address" v-show="shipToDifferentAddress">
                                                        <div class="woocommerce-shipping-fields__field-wrapper">
                                                            <p class="form-row form-row-first validate-required"
                                                                id="shipping_first_name_field" data-priority="10"><label
                                                                    for="shipping_first_name" class="">First name&nbsp;<abbr
                                                                        class="required" title="required">*</abbr></label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <input type="text"
                                                                        class="input-text "
                                                                        name="shipping_first_name"
                                                                        id="shipping_first_name"
                                                                        v-model="shippingFirstName"
                                                                        placeholder=""
                                                                        value=""
                                                                        autocomplete="given-name"></span>
                                                                        <span v-if="formSubmitted && !shippingFirstName" class="error-message">* First Name is required.</span>
                                                                    </p>
                                                            <p class="form-row form-row-last validate-required"
                                                                id="shipping_last_name_field" data-priority="20"><label
                                                                    for="shipping_last_name" class="">Last name&nbsp;<abbr
                                                                        class="required" title="required">*</abbr></label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <input type="text"
                                                                        class="input-text " 
                                                                        name="shipping_last_name"
                                                                        id="shipping_last_name" 
                                                                        v-model="shippingLastName"
                                                                        placeholder="" value=""
                                                                        autocomplete="family-name"
                                                                        >
                                                                    </span>
                                                                    <span v-if="formSubmitted && !shippingLastName" class="error-message">* Last Name is required.</span>
                                                                </p>
                                                            <p class="form-row form-row-wide" id="shipping_company_field"
                                                                data-priority="30"><label for="shipping_company"
                                                                    class="">Company
                                                                    name&nbsp;<span
                                                                        class="optional">(optional)</span></label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <input type="text"
                                                                        class="input-text " name="shipping_company"
                                                                        id="shipping_company" 
                                                                        v-model="shippingCompany"
                                                                        placeholder="" value=""
                                                                        autocomplete="organization"></span></p>
                                                            <p class="form-row form-row-wide address-field update_totals_on_change validate-required"
                                                                id="shipping_country_field" data-priority="40"><label
                                                                    for="shipping_country" class="">Country / Region&nbsp;<abbr
                                                                        class="required" title="required">*</abbr></label><span
                                                                    class="woocommerce-input-wrapper"><strong>United States
                                                                        (US)</strong><input type="hidden"
                                                                        name="shipping_country" id="shipping_country" value="US"
                                                                        autocomplete="country" class="country_to_state"></span>
                                                            </p>
                                                            <p class="form-row form-row-wide address-field validate-required"
                                                                id="shipping_address_1_field" data-priority="50"><label
                                                                    for="shipping_address_1" class="">Street address <abbr
                                                                        class="required" title="required">*</abbr></label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <input type="text"
                                                                        class="input-text " name="shipping_address_1"
                                                                        id="shipping_address_1"
                                                                        v-model="shippingAddress1"
                                                                        placeholder="House number and street name" value=""
                                                                        autocomplete="address-line1"></span>
                                                                        <span v-if="formSubmitted && !shippingAddress1" class="error-message">* Shipping Address is required.</span>
                                                                    </p>
                                                            <p class="form-row form-row-wide address-field"
                                                                id="shipping_address_2_field" data-priority="60"><label
                                                                    for="shipping_address_2"
                                                                    class="screen-reader-text">Apartment,
                                                                    suite, unit, etc.</label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <input type="text"
                                                                        class="input-text " name="shipping_address_2"
                                                                        id="shipping_address_2"
                                                                        v-model="shippingAddress2"
                                                                        placeholder="Apartment, suite, unit, etc. (optional)"
                                                                        value="" autocomplete="address-line2"></span></p>
                                                            <p class="form-row form-row-wide address-field validate-required"
                                                                id="shipping_city_field" data-priority="70"
                                                                data-o_class="form-row form-row-wide address-field validate-required">
                                                                <label for="shipping_city" class="">Town / City <abbr
                                                                        class="required" title="required">*</abbr></label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <input type="text"
                                                                        class="input-text " name="shipping_city"
                                                                        id="shipping_city"
                                                                        v-model="shippingTownCity"
                                                                        placeholder="" 
                                                                        value=""
                                                                        autocomplete="address-level2"></span>
                                                                        <span v-if="formSubmitted && !shippingTownCity" class="error-message">* City is required.</span>
                                                            </p>
                                                            <p class="form-row address-field form-row-wide validate-required validate-state woocommerce-validated"
                                                                id="billing_state_field" data-priority="80"
                                                                data-o_class="form-row address-field form-row-wide validate-required validate-state">
                                                                <label for="billing_state" class="">State <abbr class="required"
                                                                        title="required">*</abbr></label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <select name="billing_state" id="billing_state"
                                                                        class="state_select " autocomplete="address-level1"
                                                                        data-placeholder="Select an option…" data-input-classes=""
                                                                        v-model="shippingState"
                                                                        data-label="State" tabindex="-1" aria-hidden="true">
                                                                        <option value="">Select State…</option>
                                                                        <option v-for="state in usStates" :value="state.abbreviation"
                                                                            :key="state.abbreviation">
                                                                            {{ state.name }}
                                                                        </option>
                                                                    </select>
                                                                </span>
                                                                <span v-if="formSubmitted && !shippingState" class="error-message">* Shipping State is required.</span>
                                                            </p>
                                                            <p class="form-row form-row-wide address-field validate-required validate-postcode"
                                                                id="shipping_postcode_field" data-priority="90"
                                                                data-o_class="form-row form-row-wide address-field validate-required validate-postcode">
                                                                <label for="shipping_postcode" class="">ZIP Code <abbr
                                                                        class="required" title="required">*</abbr></label><span
                                                                    class="woocommerce-input-wrapper">
                                                                    <input type="text"
                                                                        class="input-text " 
                                                                        name="shipping_postcode"
                                                                        id="shipping_postcode" 
                                                                        v-model="shippingZipCode"
                                                                        placeholder="" 
                                                                        value=""
                                                                        autocomplete="postal-code"></span>
                                                                        <span v-if="formSubmitted && !shippingZipCode" class="error-message">* ZipCode is required.</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <label v-if="selectedOption === 'pickup' || isZipcodeFound === false" style="margin-top:1vh; margin-left:3px; font-weight: 600;">Name of Person Picking Up the Meals:</label>
                                                
                                                <div v-if="selectedOption === 'pickup' || isZipcodeFound === false" class="woocommerce-shipping-fields__field-wrapper">
                                                    <p class="form-row form-row-first validate-required"
                                                        id="shipping_first_name_field" data-priority="10"><label
                                                            for="shipping_first_name" class="">First name&nbsp;<abbr
                                                                class="required" title="required">*</abbr></label><span
                                                            class="woocommerce-input-wrapper">
                                                            <input type="text"
                                                                class="input-text "
                                                                name="shipping_first_name"
                                                                id="shipping_first_name"
                                                                v-model="pickupFirstName"
                                                                placeholder=""
                                                                value=""
                                                                autocomplete="given-name">
                                                            </span>
                                                            <span v-if="formSubmitted && !pickupFirstName" class="error-message">* First Name is required.</span>
                                                        </p>
                                                    <p class="form-row form-row-last validate-required"
                                                        id="shipping_last_name_field" data-priority="20"><label
                                                            for="shipping_last_name" class="">Last name&nbsp;<abbr
                                                                class="required" title="required">*</abbr></label><span
                                                            class="woocommerce-input-wrapper">
                                                            <input type="text"
                                                                class="input-text "
                                                                name="shipping_last_name"
                                                                id="shipping_last_name"
                                                                v-model="pickupLastName"
                                                                placeholder=""
                                                                value=""
                                                                autocomplete="family-name">
                                                            </span>
                                                            <span v-if="formSubmitted && !pickupLastName" class="error-message">* Last Name is required.</span>
                                                        </p>
                                                </div>

                                                <label class="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">

                                                    <div v-if="selectedOption === 'pickup' || isZipcodeFound === false">
                                                        <h3 style="color:#40c656; ">STEP 3 MAKE IT A SUBSCRIPTION?</h3>
                                                    </div>

                                                    <h3 v-if="selectedOption === 'delivery'  && isZipcodeFound === true" style="color:#40c656; margin-top: 3vh;">STEP 3 MAKE IT A SUBSCRIPTION?</h3>
                            
                                                    <input
                                                    id="ship-to-different-address-checkbox"
                                                    class="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                                                    type="checkbox"
                                                    v-model="mealsSubscription"
                                                    @change="logCheckboxStatus"
                                                    />
                                                    <span style="margin-left: 7px;">Select this box to set up a subscription that will automatically renew your weekly</span>
                                                    <br>
                                                    <span style="margin-left: 20px;">order for your convenience. Subscriptions are billed weekly, and you can easily </span>
                                                    <br>
                                                    <span style="margin-left: 20px;">adjust meal quantities and select different meals for your upcoming orders in </span>
                                                    <br>
                                                    <span style="margin-left: 20px;">the 'My Account' section..</span>
                                                    
                                                </label>
                                                <!-- <p class="form-row receive_meals_every_week form-row form-row-wide  validate-required"
                                                    id="receive_meal_field" data-priority=""><label for="receive_meal"
                                                        class="">Receive Meals Every&nbsp;<abbr class="required"
                                                            title="required">*</abbr></label><span
                                                        class="woocommerce-input-wrapper"><select name="receive_meal"
                                                            id="receive_meal" class="select enhanced"
                                                            data-placeholder="Weekly Delivery" tabindex="-1"
                                                            aria-hidden="true">
                                                            <option value="1">1 Week</option>
                                                            <option value="2">2 Weeks</option>
                                                        </select></span></p> -->
                                            </div>
                                            <!-- <div class="address_custom_note">Start typing your address, <b>then tap</b>
                                                the
                                                appropriate suggestion</div> -->
                                            <!-- <div class="delivery_schedule_main">
                                                <h3>Delivery Schedule</h3>
                                                <div class="first_order">
                                                    <div class="first_order_one">Your 1st Order With Michigan Meal Prep:</div>
                                                    <div class="first_order_two">Delivery <span>→</span></div>
                                                    <div class="first_order_three">
                                                        <div class="first_order_three_one">Ships SAME DAY - Next Day Air
                                                        </div>
                                                        <div class="first_order_three_two">If ordering Mon-Thurs BEFORE
                                                            3pm
                                                            EST<br></div>
                                                    </div>
                                                </div>
                                                <div class="seconde_order">
                                                    <div class="seconde_order_one">Your Recurring Meal Orders:</div>
                                                    <div class="seconde_order_two">Delivery <span>→</span></div>
                                                    <div class="seconde_order_three">
                                                        <div class="seconde_order_three_one">Meal Box arrives on TUES -
                                                            THURS the week after your next billing.</div>
                                                        <div class="seconde_order_three_two"></div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="woocommerce-account-fields">
                                        </div>
                                        <div class="woocommerce-additional-fields">
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <br>
                                        <div id="payment" class="woocommerce-checkout-payment payment-methode-wrapper shadow-sm p-4 border">
                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                <li class="nav-item wc_payment_method payment_method_braintree_cc"
                                                    role="presentation">
                                                    <input id="payment_method_braintree_cc" type="radio"
                                                        class="input-radio" name="payment_method" value="braintree_cc"
                                                        data-order_button_text="">

                                                    <a :class="{'nav-link': true, 'active': activeTab === 'tab1'}" 
                                                        @click="activateTab('tab1')" id="tab1-tab" data-bs-toggle="tab"
                                                        href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                                        <div class="icon">
                                                            <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                                            Credit Cards
                                                        </div>
                                                        <span class="wc-braintree-card-icons-container">
                                                            <img class="wc-braintree-card-icon closed amex"
                                                                src="https://www.flexpromeals.com/wp-content/plugins/woo-payment-gateway/assets/img/payment-methods/closed/amex.svg">
                                                            <img class="wc-braintree-card-icon closed discover"
                                                                src="https://www.flexpromeals.com/wp-content/plugins/woo-payment-gateway/assets/img/payment-methods/closed/discover.svg">
                                                            <img class="wc-braintree-card-icon closed visa"
                                                                src="https://www.flexpromeals.com/wp-content/plugins/woo-payment-gateway/assets/img/payment-methods/closed/visa.svg">
                                                            <img class="wc-braintree-card-icon closed master_card"
                                                                src="https://www.flexpromeals.com/wp-content/plugins/woo-payment-gateway/assets/img/payment-methods/closed/master_card.svg"></span>
                                                    </a>
                                                </li>
                                                <!-- <li class="nav-item wc_payment_method payment_method_braintree_paypal no-description"
                                                    role="presentation">
                                                    <input id="payment_method_braintree_paypal" type="radio"
                                                        class="input-radio" name="payment_method"
                                                        value="braintree_paypal" data-order_button_text="">

                                                    <a :class="{'nav-link': true, 'active': activeTab === 'tab2'}"
                                                        @click="activateTab('tab2')" id="tab2-tab" data-bs-toggle="tab" href="#tab2"
                                                        role="tab" aria-controls="tab1" aria-selected="true">
                                                        <div class="icon">
                                                            <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                                            PayPal
                                                        </div>
                                                        <img src="https://www.flexpromeals.com/wp-content/plugins/woo-payment-gateway/assets/img/paypal/paypal_long.svg"
                                                            alt="PayPal">
                                                    </a>
                                                </li> -->
                                                <li class="nav-item wc_payment_method gateway-pay" role="presentation">
                                                    <a class="nav-link" style="min-width: 245px;" id="tab4-tab"
                                                        data-bs-toggle="tab" href="#tab4" role="tab"
                                                        aria-controls="tab1" aria-selected="true">
                                                        <div id="wc-stripe-payment-request-wrapper" class="w-100"
                                                            style="clear:both;padding-top:1.5em;display:none;">
                                                            <div id="wc-stripe-payment-request-button">
                                                                <button id="wc-stripe-custom-button">Google Pay</button>
                                                                <!-- A Stripe Element will be inserted here. -->
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div class="tab-content" id="myTabContent">
                                                <div :class="{'tab-pane fade': true, 'active show': activeTab === 'tab1'}"
                                                    id="tab1" role="tabpanel"
                                                    aria-labelledby="tab1-tab">
                                                    <h3>Credit Cards</h3>
                                                    <div class="payment_box payment_method_braintree_cc">
                                                        <div class="wc-braintree-payment-gateway ">

                                                            <div class="wc-braintree-new-payment-method-container"
                                                                style="">

                                                                <div class="wc-braintree-cc-container">
                                                                    <div class="wc-braintree-payment-loader"></div>


                                                                    <div class="row">
                                                                        <div class="form-group col-xs-8">
                                                                            <label class="control-label">Card
                                                                                Number</label>

                                                                            <!-- <input class="form-control" id="wc-braintree-card-number"> -->
                                                                            <div class="form-control" id="card-number" ></div>
                                                                                
                                                                        </div>
                                                                        <div class="form-group col-xs-4">
                                                                            <label class="control-label">Exp
                                                                                Date</label>
                                                                            <!-- <input class="form-control" id="wc-braintree-expiration-date"> -->
                                                                            <div class="form-control" id="card-expiry"></div>
                                                                               
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-xs-6 cvv-container">
                                                                            <label class="control-label">CVV</label>
                                                                            <!-- <input class="form-control" id="wc-braintree-cvv"> -->
                                                                            <div id="card-cvc" class="form-control"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div :class="{'tab-pane fade': true, 'active show': activeTab === 'tab2'}" id="tab2" role="tabpanel"
                                                    aria-labelledby="tab1-tab">
                                                    <h3>PayPal</h3>
                                                    <div class="payment_box payment_method_braintree_paypal"
                                                        style="display: block;">
                                                        <input type="hidden" class="woocommerce_braintree_paypal_data"
                                                            data-gateway="{&quot;currency&quot;:&quot;USD&quot;,&quot;price_label&quot;:&quot;Total&quot;,&quot;merchant_account&quot;:&quot;FlexProMeals_instant&quot;,&quot;shipping_options&quot;:[],&quot;total&quot;:&quot;92.56&quot;,&quot;order_total&quot;:&quot;92.56&quot;,&quot;needs_shipping&quot;:true,&quot;items&quot;:[]}">
                                                        <div class="wc-braintree-payment-gateway ">
                                                            <input type="hidden" id="braintree_paypal_nonce_key"
                                                                name="braintree_paypal_nonce_key"
                                                                class="wc-braintree-payment-nonce" value=""><input
                                                                type="hidden" id="braintree_paypal_device_data"
                                                                name="braintree_paypal_device_data"
                                                                class="wc-braintree-device-data" value="">
                                                            <p>Click the PayPal icon below to sign into your PayPal
                                                                account
                                                                and pay securely.</p>
                                                            <div class="wc-braintree-new-payment-method-container"
                                                                style="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div v-if="!mealsSubscription && activeTab === 'tab2'" :class="{'tab-pane fade': true, 'active show': activeTab === 'tab2'}" id="paypal-button-container"></div>
                                                <div v-if="mealsSubscription && activeTab === 'tab2'" :class="{'tab-pane fade': true, 'active show': activeTab === 'tab2'}" id="paypal-subscription-button-container"></div> -->

                                            </div>
                                            <div class="form-row place-order">


                                                <p class="form-row" id="consent_checkbox_main"><label
                                                        for="consent_checkbox"
                                                        class="woocommerce-form__label woocommerce-form__label-for-checkbox"
                                                        style="padding: 1em 1em;margin: 0 0 2em;position: relative;background-color: #f7f6f7;">
                                                        <input
                                                            type="checkbox"
                                                            class="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                                                            name="consent_checkbox"
                                                            id="consent_checkbox"
                                                            @change="handleCheckboxChange"
                                                            >
                                                            I agree to receive texts about my order and related information. 
                                                            <span
                                                                class="consent_ph"
                                                                style="padding: 1em;margin: 0px 0px 2em;position: relative;background-color: rgb(236 239 189);display: none;">Phone:
                                                            <input type="text" name="consent_phone_number"
                                                                id="consent_phone_number" value=""
                                                                maxlength="10">
                                                            </span></label></p>

                                                <div class="woocommerce-terms-and-conditions-wrapper">
                                                    <div class="woocommerce-privacy-policy-text">
                                                        <p>Your personal data will be used to process your order,
                                                            support
                                                            your experience throughout this website, and for other
                                                            purposes
                                                            described in our <a
                                                            style="color:black"
                                                                href="https://www.MICHIGANMEALPREP.com/privacy-policy/"
                                                                class="woocommerce-privacy-policy-link"
                                                                target="_blank">privacy policy</a>.</p>
                                                    </div>
                                                </div>

                                                <div style="color: red;" id="card-errors" role="alert"></div>
                                                <button 
                                                    type="submit" 
                                                    class="button alt"
                                                    name="woocommerce_checkout_place_order" 
                                                    id="place_order"
                                                    value="Place Order"
                                                    data-value="Place Order"
                                                    >
                                                    Place Order
                                                </button>
                                                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="order-review-desktop" style="margin-top:3vh;">
                                    <h3 id="order_review_heading">Your order</h3>
                                    <div id="order_review" class="woocommerce-checkout-review-order">

                                        <table class="shop_table woocommerce-checkout-review-order-table">
                                            <thead>
                                                <tr>
                                                    <th class="product-name">Product</th>
                                                    <th class="product-total">Subtotal</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="!isAlacarte">
                                                <tr class="cart_item">
                                                    <td class="product-name">
                                                        {{ this.quantity }} {{ this.mealType }} Meals / Week&nbsp;
                                                        <strong class="product-quantity">×&nbsp;1</strong>
                                                    </td>
                                                    <td class="product-total">
                                                        <span class="subscription-price"><span
                                                                class="woocommerce-Price-amount amount"><bdi><span
                                                                        class="woocommerce-Price-currencySymbol">$</span>{{
                                                                        parseFloat(this.totalPrice).toFixed(2) }}</bdi></span>
                                                            <span class="subscription-details"> / week</span></span>
                                                    </td>
                                                </tr>
                                                <tr class="cart_item" v-for="(quantity, name) in this.selectedProducts"
                                                    :key="name">
                                                    <td class="product-name">
                                                        {{ name }} <strong class="product-quantity">×&nbsp;{{ quantity
                                                            }}</strong>
                                                    </td>
                                                    <td class="product-total">
                                                        $ {{ (perMealPrice * quantity).toFixed(2) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-if="isAlacarte">
                                                <tr class="cart_item">
                                                    <td class="product-name">
                                                        À LA CARTE Meals &nbsp;
                                                        <strong class="product-quantity">&nbsp;</strong>
                                                    </td>
                                                    <td class="product-total">
                                                        <span class="subscription-price">
                                                            <span
                                                                class="woocommerce-Price-amount amount">
                                                                <bdi>
                                                                    <span class="woocommerce-Price-currencySymbol">$</span>
                                                                    {{ parseFloat(this.subtotal).toFixed(2) }}
                                                                </bdi></span>
                                                            <span class="subscription-details"> / week</span></span>
                                                    </td>
                                                </tr>
                                                <tr class="cart_item" v-for="(details, name) in Object.entries(this.alacCarteselectedProducts).map(([name, details]) => { return { name: name, quantity: details.quantity, price: details.price }; })" :key="name">
                                                    <td class="product-name">
                                                        {{ details.name }} <strong class="product-quantity">×&nbsp;{{ details.quantity }}</strong>
                                                    </td>
                                                    <td class="product-total">
                                                        $ {{ (details.price * details.quantity).toFixed(2) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr class="cart-subtotal">
                                                    <th>Discount</th>
                                                    <td><span class="woocommerce-Price-amount amount"><bdi><span
                                                                    class="woocommerce-Price-currencySymbol">$</span>{{
                                                                parseFloat(this.discount).toFixed(2) }}</bdi></span>
                                                    </td>
                                                </tr>
                                                <tr class="cart-subtotal">
                                                    <th>Delivery Charges</th>
                                                    <td><span class="woocommerce-Price-amount amount"><bdi><span
                                                                    class="woocommerce-Price-currencySymbol">$</span>{{
                                                                parseFloat(this.delivery_fee).toFixed(2) }}</bdi></span>
                                                    </td>
                                                </tr>
                                                <tr class="cart-subtotal">
                                                    <th>Subtotal</th>
                                                    <td><span class="woocommerce-Price-amount amount"><bdi><span
                                                                    class="woocommerce-Price-currencySymbol">$</span>{{
                                                                parseFloat(this.subtotal).toFixed(2) }}</bdi></span>
                                                    </td>
                                                </tr>


                                                <tr class="tax-total">
                                                    <th>Tax</th>
                                                    <td><span class="woocommerce-Price-amount amount"><bdi>
                                                        <span class="woocommerce-Price-currencySymbol">$</span>
                                                        {{ parseFloat(this.tax).toFixed(2) }}</bdi></span>
                                                    </td>
                                                </tr>


                                                <tr class="order-total">
                                                    <th>Total</th>
                                                    <td><strong><span class="woocommerce-Price-amount amount"><bdi><span
                                                                        class="woocommerce-Price-currencySymbol">$</span>{{
                                                                    parseFloat(this.grandTotal).toFixed(2) }}</bdi></span></strong>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                    </div>
                                </div>
                            </form>
                            <div id="classic_pay_with_amazon"></div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

    </body>
    <FooterPage/>
    </html>
</template>

<style lang="css" src="./checkout.css" scoped></style>

<script>
import FooterPage from '../shared/footer.vue'
import { loadScript } from '@paypal/paypal-js'
import { loadStripe } from '@stripe/stripe-js';
import { globalState } from '../meals/Meal.vue';
import { globalStateCarte } from '../meals/MealPrepMeal.vue';
import { globalPlans } from '../menu/Plans.vue';
import { getCoupons } from '../meals/api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { inject } from 'vue'

export default {
    name: 'CheckoutPage',
    components: {
            FooterPage,
            PulseLoader
        },
    
    setup() {
        const cryptojs = inject('cryptojs')

        return {
        cryptojs
        }
    },
    
    data() {
        return {
            // Stripe ELements
            stripe: null,
            elements: null,
            cardBrand: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,

            // Customer Information
            billingFirstName:'',
            billingLastName:'',
            billingEmail:'',
            billingPhone:'',
            billingCompany:'',
            billingCity:'',
            billingStreet:'',
            billingAddress:'',
            billingAddress2:'',
            billingPostCode:'',
            billingZipCode:'',
            pickupLocation: '',
            billingState:'',
            showPhoneNumberInput: '',
            subscribe: false,
            formSubmitted: false,

            // Others
            selectedCountry: '',
            mealType: '',
            quantity: '',
            totalPrice: '',
            subtotal: 0.00,
            tax: 0.00,
            grandTotal: 0.00,
            discount: 0.00,
            discountPercentage: 0,
            payment_status: '',
            payment_error: '',
            payment_method: '',
            coupon_code: '',
            selectedProducts: {},
            alacCarteselectedProducts: {},
            perMealPrice: globalPlans.price,
            zipCodes: [],
            usStates: [
                { name: 'Alabama', abbreviation: 'AL' },
                { name: 'Alaska', abbreviation: 'AK' },
                { name: 'Arizona', abbreviation: 'AZ' },
                { name: 'Arkansas', abbreviation: 'AR' },
                { name: 'California', abbreviation: 'CA' },
                { name: 'Colorado', abbreviation: 'CO' },
                { name: 'Connecticut', abbreviation: 'CT' },
                { name: 'Delaware', abbreviation: 'DE' },
                { name: 'District Of Columbia', abbreviation: 'DC' },
                { name: 'Florida', abbreviation: 'FL' },
                { name: 'Georgia', abbreviation: 'GA' },
                { name: 'Hawaii', abbreviation: 'HI' },
                { name: 'Idaho', abbreviation: 'ID' },
                { name: 'Illinois', abbreviation: 'IL' },
                { name: 'Indiana', abbreviation: 'IN' },
                { name: 'Iowa', abbreviation: 'IA' },
                { name: 'Kansas', abbreviation: 'KS' },
                { name: 'Kentucky', abbreviation: 'KY' },
                { name: 'Louisiana', abbreviation: 'LA' },
                { name: 'Maine', abbreviation: 'ME' },
                { name: 'Maryland', abbreviation: 'MD' },
                { name: 'Massachusetts', abbreviation: 'MA' },
                { name: 'Michigan', abbreviation: 'MI' },
                { name: 'Minnesota', abbreviation: 'MN' },
                { name: 'Mississippi', abbreviation: 'MS' },
                { name: 'Missouri', abbreviation: 'MO' },
                { name: 'Montana', abbreviation: 'MT' },
                { name: 'Nebraska', abbreviation: 'NE' },
                { name: 'Nevada', abbreviation: 'NV' },
                { name: 'New Hampshire', abbreviation: 'NH' },
                { name: 'New Jersey', abbreviation: 'NJ' },
                { name: 'New Mexico', abbreviation: 'NM' },
                { name: 'New York', abbreviation: 'NY' },
                { name: 'North Carolina', abbreviation: 'NC' },
                { name: 'North Dakota', abbreviation: 'ND' },
                { name: 'Ohio', abbreviation: 'OH' },
                { name: 'Oklahoma', abbreviation: 'OK' },
                { name: 'Oregon', abbreviation: 'OR' },
                { name: 'Pennsylvania', abbreviation: 'PA' },
                { name: 'Rhode Island', abbreviation: 'RI' },
                { name: 'South Carolina', abbreviation: 'SC' },
                { name: 'South Dakota', abbreviation: 'SD' },
                { name: 'Tennessee', abbreviation: 'TN' },
                { name: 'Texas', abbreviation: 'TX' },
                { name: 'Utah', abbreviation: 'UT' },
                { name: 'Vermont', abbreviation: 'VT' },
                { name: 'Virginia', abbreviation: 'VA' },
                { name: 'Washington', abbreviation: 'WA' },
                { name: 'West Virginia', abbreviation: 'WV' },
                { name: 'Wisconsin', abbreviation: 'WI' },
                { name: 'Wyoming', abbreviation: 'WY' },
                { name: 'Armed Forces (AA)', abbreviation: 'AA' },
                { name: 'Armed Forces (AE)', abbreviation: 'AE' },
                { name: 'Armed Forces (AP)', abbreviation: 'AP' }
            ],

            canadaStates: [
                { name: 'Alberta', abbreviation: 'AB' },
                { name: 'British Columbia', abbreviation: 'BC' },
                { name: 'Manitoba', abbreviation: 'MB' },
                { name: 'New Brunswick', abbreviation: 'NB' },
                { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
                { name: 'Nova Scotia', abbreviation: 'NS' },
                { name: 'Northwest Territories', abbreviation: 'NT' },
                { name: 'Nunavut', abbreviation: 'NU' },
                { name: 'Ontario', abbreviation: 'ON' },
                { name: 'Prince Edward Island', abbreviation: 'PE' },
                { name: 'Quebec', abbreviation: 'QC' },
                { name: 'Saskatchewan', abbreviation: 'SK' },
                { name: 'Yukon', abbreviation: 'YT' }
            ],
            countries: [
                { abbreviation: 'US', name: 'United States' },
                { abbreviation: 'CA', name: 'Canada' }
            ],

            // Coupons
            showCoupon: false,
            showCouponError: true,
            couponFound: true,
            couponCode: '',

            // Tabs
            activeTab: 'tab1',

            // Transactions Status
            paypalSuccess: false,
            stripeSuccess: false,
            payer: '',

            // Delivery Options
            selectedOption: 'pickup',

            // Picup locations
            pickupLocations: [],

            // delivery fee
            delivery_fee: 0.00,

            // Zip code filter
            filteredZipcodes: [],
            searchQuery: '',
            showDropdown: true,

            // Subscription
            mealsSubscription: false,

            // Submit Payment loading
            loading: false,

            // Shipping to a Different Address
            shipToDifferentAddress: false,
            shippingFirstName:'',
            shippingLastName:'',
            shippingCompany:'',
            shippingAddress1:'',
            shippingAddress2:'',
            shippingTownCity:'',
            shippingState:'',
            shippingZipCode:'',

            // Zipcode found or not?
            isZipcodeFound: true,

            // Pickup Customer Information
            pickupFirstName: '',
            pickupLastName: '',
            subscriptionId: '',

            isAlacarte: false,
            showCouponMessage: false

        };
    },
    
    async mounted() {
        window.scrollTo(0, 0);
        document.title = this.$route.meta.title || 'Default Title';
        // Stripe Integration
        this.readZipCodesFile();
        this.readpickupLocationsFile();
        // Load Stripe
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
        this.elements = this.stripe.elements();

        // Create Elements
        const style = {
        base: {
            color: "#32325d",
        },
        // Add custom styling here
        };
        this.filteredZipcodes = this.zipcodes;

        // card Number
        this.cardNumber = this.elements.create('cardNumber', { style: style });
        this.cardNumber.mount('#card-number');
        this.cardNumber.on('change', ({brand}) => {
        // Update the card brand whenever the card number changes
        this.cardBrand = brand.charAt(0).toUpperCase() + brand.slice(1); // Capitalize the first letter
        // Optionally, handle displaying logos or other visual cues for the card brand
        });

        this.cardExpiry = this.elements.create('cardExpiry', { style: style });
        this.cardExpiry.mount('#card-expiry');

        this.cardCvc = this.elements.create('cardCvc', { style: style });   
        this.cardCvc.mount('#card-cvc');

        const setupErrorHandling = (element) => {
            element.addEventListener('change', ({ error }) => {
                const displayError = document.getElementById('card-errors');
                if (error) {
                    displayError.textContent = '* ' + error.message;
                } else {
                    displayError.textContent = '';
                }
            });
        };

        setupErrorHandling(this.cardNumber);
        setupErrorHandling(this.cardExpiry);
        setupErrorHandling(this.cardCvc);
    },
    created() {
        if (globalState.selectedProducts && Object.keys(globalState.selectedProducts).length > 0) {
            console.log('loading');
        } else {
            window.location.href = process.env.VUE_APP_BASE_URL;
        }
        
        this.selectedProducts = globalState.selectedProducts;
        this.mealType = globalPlans.mealType;
        this.quantity = globalPlans.quantitySelected;
        this.totalPrice = globalPlans.price * this.quantity;
        this.tax = parseFloat((this.totalPrice * 0.06).toFixed(2));
        this.subtotal = this.totalPrice || globalStateCarte.totalAmount ;
        this.grandTotal = this.subtotal + this.tax + this.delivery_fee;
        if (Object.keys(globalStateCarte.orderInfoAlacarte).length > 0) {
            this.isAlacarte = true,
            this.tax = parseFloat((this.subtotal * 0.06).toFixed(2));
            this.grandTotal = this.subtotal + this.tax + this.delivery_fee;
            this.alacCarteselectedProducts = globalStateCarte.orderInfoAlacarte
        }
    },
    computed: {
        states() {
            if (this.selectedCountry === 'US') {
                return this.usStates;
            } else if (this.selectedCountry === 'CA') {
                return this.canadaStates;
            } else {
                return [];
            }
        },
        zipcodes() {
            return this.zipCodes
        }
    },
    methods: {

        showCouponStatusMessage() {
            this.showCouponMessage = true;
            setTimeout(() => {
                this.showCouponMessage = false;
            }, 5000); // 5000 milliseconds = 5 seconds
        },

        // Paypal Payment
        // Initialize PayPal SDK
        // PayPal Payment
        // Initialize PayPal SDK
        async loadPayPalScript() {
            if (!window.paypal) {
                return new Promise((resolve, reject) => {
                    loadScript({
                        // live
                        'client-id': 'ASeSwSKR2aSg1fz8D1ERNIJbPsL8kAxQT85587q8YqUu5-T7S6pXJ46L0KqlviwlmXWBJe50LofGUOKA',
                        currency: 'USD',
                        intent: this.mealsSubscription ? 'subscription' : '',
                        vault: this.mealsSubscription
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        console.error('Failed to load PayPal script', error);
                        reject(error);
                    });
                });
            } else {
                console.log('PayPal SDK is already loaded');
                return Promise.resolve();
            }
        },

        async unloadPayPalScript() {
            if (window.paypal) {
                delete window.paypal;
                console.log('PayPal SDK unloaded');
            }
        },

        removeExistingButtons() {
            const buttonContainer = document.getElementById('paypal-button-container');
            const subscriptionButtonContainer = document.getElementById('paypal-subscription-button-container');
            
            if (buttonContainer) {
                buttonContainer.innerHTML = '';
            }

            if (subscriptionButtonContainer) {
                subscriptionButtonContainer.innerHTML = '';
            }
        },

        async initializePayPalButton() {
            if (!window.paypal) {
                console.error('PayPal SDK is not loaded');
                return;
            }

            try {
                this.removeExistingButtons();

                const buttons = window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: this.grandTotal // set the amount here
                                }
                            }]
                        });
                    },
                    onApprove: (data, actions) => {
                        return actions.order.capture().then((details) => {
                            this.paypalSuccess = true;
                            this.payment_status = 'successful';
                            this.payer = details.payer.name.given_name;
                            // Call your backend to save the transaction
                            console.log('Init Payment from Paypal');
                            this.sendOrderInformation();
                            this.goToPaymentSuccess();
                        });
                    }
                });

                if (buttons.isEligible()) {
                    buttons.render('#paypal-button-container');
                } else {
                    console.error('PayPal Buttons are not eligible for rendering');
                }
            } catch (error) {
                console.error('Error initializing PayPal Buttons', error);
            }
        },

        async initializePayPalSubscriptionButton(subscriptionPlanId) {
            if (!window.paypal) {
                console.error('PayPal SDK is not loaded');
                return;
            }

            try {
                this.removeExistingButtons();

                const buttons = window.paypal.Buttons({
                    createSubscription: (data, actions) => {
                        return actions.subscription.create({
                            plan_id: subscriptionPlanId // set your plan ID here
                        });
                    },
                    onApprove: (data) => {
                        console.log('Subscription approved');
                        this.payment_status = 'successful';
                        // Handle the approval and update your backend if necessary
                        this.subscriptionSuccess = true;
                        this.subscriptionId = data.subscriptionID;
                        this.sendOrderInformation();
                        this.goToPaymentSuccess();
                    }
                });

                if (buttons.isEligible()) {
                    buttons.render('#paypal-subscription-button-container');
                } else {
                    console.error('PayPal Buttons are not eligible for rendering');
                }
            } catch (error) {
                console.error('Error initializing PayPal Subscription Buttons', error);
            }
        },

        async fetchSubscriptionPlanId() {
            this.loading = true;
            const orders = Object.entries(this.selectedProducts).map(([name, quantity]) => {
                return `${name} x ${quantity}`;
            }).join('\n');

            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL+'/api/create-paypal-plan', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        product_name: this.mealType + " Meals / Week",
                        product_description: orders,
                        plan_name: this.mealType + " Meals / Week",
                        plan_description: orders,
                        plan_price: this.grandTotal
                    })
                });
                const data = await response.json();
                this.loading = false;
                await this.initializePayPalSubscriptionButton(data.plan_id);
            } catch (error) {
                this.loading = false;
                console.error('Error creating PayPal plan:', error);
            }
        },

        // Check Active Payment TAB
        async activateTab(tab) {
            this.activeTab = tab;
            await this.unloadPayPalScript();

            if (tab === 'tab2') {
                this.payment_method = 'PayPal';
                await this.loadPayPalScript();

                if (this.mealsSubscription) {
                    await this.fetchSubscriptionPlanId();
                } else {
                    await this.initializePayPalButton();
                }
            } else {
                await this.unloadPayPalScript();
            }
        },

        // Subscription CheckBox
        async logCheckboxStatus() {
            if (this.activeTab === 'tab2') {
                await this.unloadPayPalScript();
                await this.loadPayPalScript();

                if (this.mealsSubscription) {
                    await this.fetchSubscriptionPlanId();
                } else {
                    await this.initializePayPalButton();
                }
            } else {
                await this.unloadPayPalScript();
            }
        },



        // Goto Payment Success Page
        goToPaymentSuccess() {
            const orders = Object.entries(this.selectedProducts).map(([name, quantity]) => {
                return { name, quantity  };
            });

            let paymentType = '';
            if (this.mealsSubscription == true){
                paymentType = "Weekly Subscription";
            } else {
                paymentType = "Meal Order Payment";
            }

            this.$router.push({ 
                name: 'payment-success', 
                params: { 
                    subTotal: this.subtotal,
                    amount: this.grandTotal, 
                    taxAmount: this.tax,
                    deliveryCharges: this.delivery_fee,
                    orders: JSON.stringify(orders),
                    customerName: this.billingFirstName + ' ' + this.billingLastName,
                    deliveryAddress: this.billingAddress + ' ' + this.billingAddress2,
                    paymentType: paymentType,
                    discountAmount: this.discount,
                    couponCode: this.couponCode
                } 
            });
        },

        // Zip code check
        filterZipCodes() {
            this.filteredZipcodes = this.zipcodes.filter(zipcode =>
                zipcode.includes(this.searchQuery)
            );
            this.showDropdown = true; // Show dropdown when filtering

            if (this.filteredZipcodes.length === 0) {
                this.isZipcodeFound = false;
                this.delivery_fee = 0;
            } else {
                this.isZipcodeFound = true;
                this.delivery_fee = 30;
            }
        },
        selectZipCode(zipcode) {
            this.billingPostCode = zipcode;
            this.searchQuery = zipcode; // Set the search query to the selected zip code
            this.showDropdown = false; // Hide dropdown after selecting
        },

        // Delivery Options
        optionChanged() {
            console.log('Selected Option:', this.selectedOption);
            // Call your function here based on the selected option
            if (this.selectedOption === 'delivery') {
                this.handleDeliveryOption();
            } else if (this.selectedOption === 'pickup') {
                this.handlePickupOption();
            }
        },
        handleDeliveryOption() {
            // Implement your logic for the delivery option here
            this.delivery_fee = 30.00;
            this.pickupLocation = "";
            this.tax = this.subtotal + this.delivery_fee;
            this.tax = parseFloat((this.tax * 0.06).toFixed(2))
            const subDiscountTotal = this.subtotal + this.delivery_fee;
            this.grandTotal = this.subtotal + this.tax + this.delivery_fee;

            if (this.couponFound) {
                const discount_precentage = this.discountPercentage/100
                // console.log(`Coupon with ID ${couponCode} found`);
                const discountAmount = subDiscountTotal * discount_precentage;
                this.discount = discountAmount;
                const subtotal = subDiscountTotal - discountAmount;
                const tax = parseFloat((subtotal * 0.06).toFixed(2));
                this.grandTotal = subtotal + tax ;
            }
        },
        handlePickupOption() {
            // Implement your logic for the pickup option here
            this.delivery_fee = 0.0
            this.billingPostCode = ""
            this.tax = this.subtotal + this.delivery_fee;
            this.tax = parseFloat((this.tax * 0.06).toFixed(2))
            this.grandTotal = this.subtotal + this.tax + this.delivery_fee;
            const subDiscountTotal = this.subtotal + this.delivery_fee;
            if (this.couponFound) {
                const discount_precentage = this.discountPercentage/100
                // console.log(`Coupon with ID ${couponCode} found`);
                const discountAmount = subDiscountTotal * discount_precentage;
                this.discount = discountAmount;
                const subtotal = subDiscountTotal - discountAmount;
                this.tax = parseFloat((subtotal * 0.06).toFixed(2));
                this.grandTotal = subtotal + this.tax ;
            }
        },

        // On Check CheckBox "I agree and recieve text.."
        handleCheckboxChange(event) {
            if (event.target.checked) {
                this.showPhoneNumberInput = true;
                this.subscribe = true;
            } else {
                this.showPhoneNumberInput = false;
                this.subscribe = false;
            }
        },

        // GET The Order Information to send to API
        getOrderInformation() {
            const orders = Object.entries(this.selectedProducts).map(([name, quantity]) => {
                return { name, quantity };
            });
            let shippingInformation;
            let mQUantity = ''
            let mType = ''

            if (this.quantity == '5'){
                mQUantity = '05'
            } else {
                mQUantity = this.quantity
            }

            if (this.mealType == 'Large'){
                mType = 'l'
            } else if(this.mealType == 'Regular') {
                mType = 's'
            }

            //  Encryprt Subscripiton Update URL
            let subscription_id_order_ = this.subscriptionId + mQUantity + mType;
            let subscription_id_order = this.cryptojs.AES.encrypt(subscription_id_order_, "mmp_order_sub").toString();
            subscription_id_order = encodeURIComponent(subscription_id_order); // Make the encrypted string URL safe
            // console.log(`Encrypted Subscription ID: ${subscription_id_order}`);

            //  Encryprt Subscripiton Cancel URL
            // let subscription_id_cancel_ = this.subscriptionId;
            // let subscription_id_cancel = this.cryptojs.AES.encrypt(subscription_id_cancel_, "mmp_order_sub").toString();
            // subscription_id_cancel = encodeURIComponent(subscription_id_cancel); // Make the encrypted string URL safe

            // Use the encrypted ID in the URL
            const subscriptionUpdateURL = `${process.env.VUE_APP_BASE_URL}/subscription/?subscriptionId=${subscription_id_order}`;
            // const subscriptionCancelURL = `${process.env.VUE_APP_BASE_URL}/cancel/?subscriptionId=${subscription_id_cancel}`
            // console.log(`Generated URL: ${subscriptionUpdateURL}`);

            if (this.shipToDifferentAddress === true) {
                shippingInformation = {
                    name: this.shippingFirstName + ' ' + this.shippingLastName,
                    company: this.shippingCompany,
                    country: 'United States (US)',
                    streetAddress: this.shippingAddress1 + this.shippingAddress2,
                    townCity: this.shippingTownCity,
                    state: this.shippingState,
                    zipCode: this.shippingZipCode
                }
            } else {
                shippingInformation = {
                    name: this.billingFirstName + ' ' + this.billingLastName,
                    company: "",
                    country: this.selectedCountry,
                    streetAddress: this.billingAddress + this.billingAddress2,
                    townCity: this.billingCity,
                    state: this.billingState,
                    zipCode: this.billingZipCode
                }
            }

           const order_details = {
                    name: this.billingFirstName + ' ' + this.billingLastName,
                    address: {
                        city: this.billingCity,
                        country: this.selectedCountry,
                        postal_code: this.billingZipCode,
                        state: this.billingState,
                        address1: this.billingAddress,
                        address2: this.billingAddress2,
                    },
                    shippingInformation: shippingInformation,
                    pickupDetails: {
                        pickupLocation: this.pickupLocation,
                        name: this.pickupFirstName + ' ' + this.pickupLastName,
                    },
                    orders : orders,
                    email: this.billingEmail,
                    phone: this.billingPhone,
                    discountPercentage: this.discountPercentage,
                    discountAmount: this.discount,
                    tax: this.tax,
                    quantity: this.quantity,
                    subTotal: this.subtotal,
                    mealType: this.mealType,
                    grandTotal: this.grandTotal,
                    subscribe: this.subscribe,
                    payment: this.payment_status,
                    payment_detail: this.payment_error,
                    paymentMethod: this.payment_method,
                    couponCode: this.coupon_code,
                    deliveryZipCode: this.billingPostCode,
                    deliveryFee: this.delivery_fee,
                    weeklySubscription: this.mealsSubscription,
                    subscriptionId: this.subscriptionId,
                    subscriptionUpdateURL: subscriptionUpdateURL,
                    subscriptionCancelURL: 'https://billing.stripe.com/p/login/4gw3dF1Kk6DK29WcMM'
                }
            return order_details;
        },

        // POST Order Information to API
        sendOrderInformation() {
            console.log('Sending Information to API')
            const orderDetails = this.getOrderInformation();
            const orderDetailsJson = JSON.stringify(orderDetails);
            let order_url = ''

            if (this.payment_status == 'pending'){
                order_url = 'https://backend.leadconnectorhq.com/hooks/hrCAxvZl4GT8uGeT9GWF/webhook-trigger/df3f0334-f47f-4f96-8c6f-8a02252a64ee';
            } else {
                order_url = 'https://backend.leadconnectorhq.com/hooks/hrCAxvZl4GT8uGeT9GWF/webhook-trigger/wMjeor6bv2Jw0L1xeHNc'
            }

            fetch(order_url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: orderDetailsJson,
            })
            .then(response => response.json())
            .then(data => {
                console.log(data.status);
            })
            .catch(error => {
                console.error('Error submitting order:', error);
            });
        },

        // Read file to GET Zip Codes
        readZipCodesFile() {
            fetch(process.env.BASE_URL + 'zip_codes.txt')
            .then(response => response.text())
            .then(text => {
                const lines = text.split('\n');
                this.zipCodes = lines
            })
            .catch(error => {
                console.error('Error reading file:', error);
            });
        }, 

        // Read file to GET pickup Locations
        readpickupLocationsFile() {
            fetch(process.env.BASE_URL + 'pickup_location.txt')
            .then(response => response.text())
            .then(text => {
                const lines = text.split('\n');
                this.pickupLocations = lines
            })
            .catch(error => {
                console.error('Error reading file:', error);
            });
        },

        toggleCoupon() {
            this.showCoupon = !this.showCoupon;
        },

        //  FETCH Coupon and Check.
        // If cououn matches apply % discount to total price
        async fetchCoupons() {
            const response = await getCoupons();
            const coupons = response.data.data;

            const couponCode = document.getElementById('coupon_code').value;

            if (coupons.length > 0) {
                const matchedCoupon = coupons.find(coupon => coupon.code === couponCode);
                if (matchedCoupon) {
                    // const percentage_off = coupon.percent_off
                    const discount_precentage = matchedCoupon['coupon'].percent_off/100
                    // console.log(`Coupon with ID ${couponCode} found`);
                    this.discountPercentage = matchedCoupon['coupon'].percent_off

                    const subDiscountTotal = this.subtotal + this.delivery_fee;
                    const discountAmount = subDiscountTotal * discount_precentage;
                    this.discount = discountAmount;
                    const subtotal = subDiscountTotal - discountAmount;
                    this.tax = parseFloat((subtotal * 0.06).toFixed(2));
                    this.grandTotal = subtotal + this.tax ;


                    this.couponCode = couponCode;
                    this.couponFound = true;
                    this.showCouponError = false;
                    this.showCoupon = false;
                    this.coupon_code = matchedCoupon.id;

                } else {
                    // console.log(`Coupon with ID ${couponCode} not found`);
                    this.couponCode = couponCode;
                    this.couponFound = false;
                    this.showCouponError = false;
                    this.showCoupon = false;
                }
            } else {
                console.log('No coupons found');
            }
        },
        getCookie(name) {
            let cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i].trim();
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        },
        submitPayment() {
            if (Object.keys(this.selectedProducts).length === 0) {
                alert("Please Select a Product before proceeding with Payments.");
            } else {
                if (this.mealsSubscription == true){
                    this.subscriptionPayment()
                } else {
                    this.nonSubscriptionPayment()
                }
            }
      
        },

        setupErrorHandling(event) {
            const { error } = event;
            this.handleError(error);
        },
        handleError(error) {
            const displayError = document.getElementById('card-errors');
            if (error) {
                displayError.textContent = '* ' + error;
            } else {
                displayError.textContent = '';
            }
        },

        validateFormSubmission() {
            if (this.selectedOption == 'pickup') {
                if (this.pickupLocation == '') {
                    return false;
                }
            } else if (this.selectedOption == 'delivery') {
                if (this.shipToDifferentAddress == true) {
                    if(
                        !this.shippingFirstName ||
                        !this.shippingLastName ||
                        !this.shippingAddress1 ||
                        !this.shippingTownCity ||
                        !this.shippingState
                    ) {
                        return false;
                    }
                }
            }

            if (
                !this.billingFirstName ||
                !this.billingEmail ||
                !this.billingPhone ||
                !this.selectedCountry ||
                !this.billingAddress
            ) {
                return false;
            }

            return true;
        },

        // Non Subscription Stripe Payments
        nonSubscriptionPayment() {
            this.handleError("");
            if (this.validateFormSubmission()) {
                this.loading = true;
                this.payment_method = 'Stripe';
                let csrfToken = this.getCookie('csrftoken');
                //this.grandTotal = this.grandTotal + this.discount;
                //this.discount = 0
                const amountInCents = Math.round(this.grandTotal * 100);
                console.log('CSRF Token:', csrfToken);

                if (!csrfToken) {
                    console.error('CSRF token not found');
                    csrfToken = 'oTplf02hPbvx02WRzuljBjiE5XfJbmhr';
                }

                const products = [
                    // additionalProduct,
                    ...Object.entries(this.selectedProducts).map(([name, quantity]) => {
                        return { name: name, quantity: quantity, price: Math.round(globalPlans.price * 100) };
                    })
                ];

                const requestData = {
                    amount: amountInCents,
                    subscription: false, // Pass the subscription flag
                    billingEmail: this.billingEmail, // Pass the customer ID for subscriptions
                    products: products,
                    coupon_code: this.coupon_code,
                };

                fetch(`${process.env.VUE_APP_BASE_URL}/api/create-payment-intent/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(requestData)
                })
                .then(response => {
                    if (!response.ok) {
                        this.payment_status = 'failed';
                        
                        return response.json().then(errorData => {
                            this.payment_error = errorData.error;
                            this.sendOrderInformation();
                            this.handleError(errorData.error);
                            throw new Error(errorData.error || 'Network response was not ok');
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.clientSecret) {
                        this.stripe.confirmCardPayment(data.clientSecret, {
                            payment_method: {
                                card: this.cardNumber,
                                billing_details: {
                                    name: this.billingFirstName + ' ' + this.billingLastName,
                                    address: {
                                        city: this.billingCity,
                                        country: this.selectedCountry,
                                        postal_code: this.billingPostCode,
                                        state: this.billingState,
                                        line1: this.billingAddress,
                                        line2: this.billingAddress2,
                                    },
                                    email: this.billingEmail,
                                    phone: this.billingPhone,
                                },
                            },
                            // shipping
                            shipping: {
                                name: this.pickupFirstName + ' ' + this.pickupLastName || this.shippingFirstName + ' ' + this.shippingLastName || this.billingFirstName + ' ' + this.billingLastName,
                                address: {
                                    line1: this.pickupLocation || this.shippingAddress1 || this.billingAddress,
                                    line2: this.shippingAddress2 || this.billingAddress2,
                                    city: this.shippingTownCity || this.billingCity,
                                    state: this.shippingState || this.billingState,
                                    postal_code: this.shippingZipCode || this.billingPostCode,
                                    country: this.selectedCountry || 'United States (US)',
                                },
                                phone: this.shippingPhone || this.billingPhone,
                            }
                        }).then(result => {
                            this.loading = false;
                            if (result.error) {
                                this.payment_status = 'failed';
                                this.payment_error = result.error.message;
                                this.sendOrderInformation();
                                this.handleError(result.error.message);
                            } else {
                                if (result.paymentIntent.status === 'succeeded') {
                                    this.stripeSuccess = true;
                                    this.payment_status = 'successful';
                                    this.sendOrderInformation();
                                    this.goToPaymentSuccess();
                                }
                            }
                        });
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.error('Error during fetch:', error);
                });
            } else {
                this.formSubmitted = true;
            }
        },

        // Stripe Subscription Payments
        subscriptionPayment() {
            if (this.validateFormSubmission()) {
                this.handleError("");
                const amountInCents = Math.round(this.grandTotal * 100); // Ensure amount is an integer
                this.stripe.createPaymentMethod({
                    type: 'card',
                    card: this.cardNumber,
                    billing_details: {
                        name: this.billingFirstName + ' ' + this.billingLastName,
                        email: this.billingEmail,
                        phone: this.billingPhone,
                        address: {
                            city: this.billingCity,
                            country: this.selectedCountry,
                            postal_code: this.billingPostCode,
                            line1: this.billingAddress,
                            line2: this.billingAddress2,
                        },
                    },
                })
                .then(result => {
                    if (result.error) {
                        this.payment_status = 'failed';
                        this.payment_error = result.error.message;
                        this.handleError(result.error.message);
                        this.sendOrderInformation();
                    } else {
                        this.loading = true;
                        this.paymentMethodId = result.paymentMethod.id;
                        let products;

                        // const deliveryCharges = { name: "Shipping (Ground Shipping)", quantity: 1, price: Math.round(this.delivery_fee * 100) };
                        // const tax = { name: "Tax", quantity: 1, price: 525 };

                        if (Object.keys(globalStateCarte.orderInfoAlacarte).length > 0) {
                            console.log('alacarte meals')
                            products = [
                                // addition`alProduct,
                                ...Object.entries(globalStateCarte.orderInfoAlacarte).map(([name, details]) => {
                                    return { name: name, quantity: details.quantity, price: Math.round(details.price * 100) };
                                })
                            ];
                        } else {
                            products = [
                                // additionalProduct,
                                ...Object.entries(this.selectedProducts).map(([name, quantity]) => {
                                    return { name: name, quantity: quantity, price: Math.round(globalPlans.price * 100) };
                                })
                            ];
                        }

                        const requestData = {
                            amount: amountInCents,
                            subscription: true,
                            billingEmail: this.billingEmail,
                            paymentMethodId: this.paymentMethodId,
                            products: products, // Pass the products array for reference
                            city: this.billingCity,
                            country: this.selectedCountry,
                            postal_code: this.billingPostCode,
                            state: this.billingState,
                            address: this.billingAddress + this.billingAddress2,
                            phone: this.billingPhone,
                            name: this.billingFirstName + this.billingLastName,
                            shipping_cost: Math.round(this.delivery_fee * 100),
                            coupon_code: this.coupon_code,
                            // shipping
                            shipping_name: this.pickupFirstName + ' ' + this.pickupLastName || this.shippingFirstName + ' ' + this.shippingLastName || this.billingFirstName + this.billingLastName,
                            shipping_address: this.pickupLocation || this.shippingAddress1 + this.shippingAddress2 || this.billingAddress + this.billingAddress2,
                            shipping_city : this.shippingTownCity || this.billingCity,
                            shipping_state : this.shippingState || this.billingState,
                            shipping_postal_code : this.shippingZipCode || this.billingPostCode,
                            shipping_country : 'United States (US)' || this.selectedCountry,
                            shipping_phone : this.billingPhone,
                        };

                        fetch(`${process.env.VUE_APP_BASE_URL}/api/create-payment-intent/`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                            },
                            body: JSON.stringify(requestData)
                        })
                        .then(response => {
                            // Check if the response is OK
                            if (!response.ok) {
                                this.payment_status = 'failed';
                                // If not OK, parse the response body to get the error message
                                return response.json().then(errorData => {
                                    this.payment_error = errorData.error;
                                    this.sendOrderInformation();
                                    this.handleError(errorData.error);
                                    throw new Error(errorData.error || 'Network response was not ok');
                                });
                            }
                            return response.json();
                        })
                        .then(data => {
                            if (data.clientSecret) {
                                this.subscriptionId = data.subscriptionId;
                                this.stripe.confirmCardPayment(data.clientSecret, {
                                    payment_method: {
                                        card: this.cardNumber,
                                        billing_details: {
                                            name: this.billingFirstName + ' ' + this.billingLastName,
                                            address: {
                                                city: this.billingCity,
                                                country: this.selectedCountry,
                                                postal_code: this.billingPostCode,
                                                state: this.billingState,
                                                line1: this.billingAddress,
                                                line2: this.billingAddress2,
                                            },
                                            email: this.billingEmail,
                                            phone: this.billingPhone,
                                        },
                                    },
                                }).then(result => {
                                    this.loading = false;
                                    if (result.error) {
                                        this.payment_status = 'failed';
                                        this.payment_error = result.error.message;
                                        this.handleError(result.error.message);
                                        this.sendOrderInformation();
                                        console.log('result.error.message', result.error.message);
                                    } else {
                                        if (result.paymentIntent.status === 'succeeded') {
                                            this.stripeSuccess = true;
                                            this.payment_status = 'successful';
                                            this.sendOrderInformation();
                                            this.goToPaymentSuccess();
                                        }
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            this.loading = false;

                            if (this.couponCode != '') {
                                this.handleError(`Error: ${error.message} Discount Removed, Please Try Again. `);
                                console.error('Error during fetch:', error.message + ' Discount Removed, Please Try Again');
                            } else {
                                console.error('Error during fetch:', error.message);
                            }
                            
                            this.coupon_code = ''
                            // this.subtotal += this.discount;
                            this.tax = parseFloat((this.subtotal * 0.06).toFixed(2))
                            this.grandTotal = this.subtotal + this.tax + this.delivery_fee;
                            this.discount = 0
                            
                        });
                    }
                });
            } else {
                this.formSubmitted = true;
            }
        }

  },
  watch: {
    // Watch the couponFound and showCouponError properties to trigger the message display
    couponFound(newVal) {
        this.showCouponStatusMessage(newVal);
    },
    showCouponError(newVal) {
        this.showCouponStatusMessage(!newVal);
    }
}
};
</script>
<template>
    <!DOCTYPE html>
    <html lang="en-US" prefix="og: https://ogp.me/ns#">

    <head>
        <meta charset="UTF-8">
        <title>Michigan Meal Prep</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
    </head>

    <body>
        <div data-elementor-type="wp-page" data-elementor-id="34024" class="elementor elementor-34024">

            <MenuTop/>
            <Plans/>

        </div>
    </body>

    </html>
</template>

<style>
    @import './menufull.css';
</style>

<script>
    // document.title = this.$route.meta.title;
    import Plans from './Plans.vue'
    import MenuTop from '../shared/MenuTop.vue'
    export default {
        name: 'MainMenu',
        components: {
            MenuTop,
            Plans
        },
    mounted() {
        document.title = this.$route.meta.title || 'Default Title';
    },
    };
</script>

<template>
    <html lang="en-US" prefix="og: https://ogp.me/ns#">

    <head>

        <title>Meals - MICHIGAN MEAL PREP</title>

    </head>

    <body
        class="page-template page-template-tpl-vip-menu page-template-tpl-vip-menu-php page page-id-212 wp-embed-responsive theme-flexpro bt-hidevars wc-braintree-body is-light-theme singular has-main-navigation elementor-default elementor-kit-4104 e--ua-blink e--ua-chrome e--ua-webkit clickup-chrome-ext_installed"
        data-elementor-device-mode="desktop">
        <div class="vip-flex-meals-popup" style="display: none;">
            <div class="vip-flex-meals-popup-content">
                <div class="vip-flex-meals-popup-content1">
                    <span class="heading">Your Meal Box Is Ready</span>
                </div>
                <div class="vip-flex-meals-popup-content2">
                    <div class="heading"></div>
                    <div class="sub-heading"></div>
                    <div class="checkout-link-main">
                        <a href="javascript:void(0)" class="checkout-link">Express Checkout <span>→</span></a>
                    </div>
                    <div class="select-own">Click here to edit selections.</div>
                </div>
            </div>
        </div>
        <div class="vip-flex-notice-popup" style="display: none;">
            <div class="vip-flex-notice-popup-content">
                <div class="vip-flex-notice-popup-content1">
                    <span class="heading">Alert</span>
                </div>
                <div class="vip-flex-notice-popup-content2">
                    <div class="heading">All the pre selected meals will be removed!</div>
                    <div class="sub-heading">Are you sure you want to proceed?</div>
                    <div class="notice-link-main">
                        <a href="javascript:void(0)" class="notice-link">Confirm <span>→</span></a>
                    </div>
                    <div class="notice-select-own">Cancel</div>
                    <input type="hidden" name="vip_previous_selected_cate" class="vip_previous_selected_cate"
                        id="vip_previous_selected_cate" value="c_keto">
                    <input type="hidden" name="vip_selected_cate" class="vip_selected_cate" id="vip_selected_cate"
                        value="c_keto">
                </div>
            </div>
        </div>
        
        <div class="vip-flex-remove-popup">
            <div class="vip-flex-remove-popup-content">
                <div class="vip-flex-remove-popup-content1">
                    <span class="heading">Alert</span>
                </div>
                <div class="vip-flex-remove-popup-content2">
                    <div class="heading">All the pre selected meals will be removed!</div>
                    <div class="sub-heading">Are you sure you want to proceed?</div>
                    <div class="remove-link-main">
                        <a href="javascript:void(0)" class="remove-link">Confirm <span>→</span></a>
                    </div>
                    <div class="remove-select-own">Cancel</div>
                </div>
            </div>
        </div>
        
        <section class="meall for-bottom flex-vip-menu-main-class">
            
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                        <a href="https://michiganmealprep.com/"><img width="177" height="67"
                            :src="require('@/assets/logo.jpg')" class="w-100"
                                style="max-width: 125px!important;"></a>
                    </div>
                    <div class="col-8 col-sm-9 col-md-9 col-lg-9 col-xl-10">
                        <div class="flexy-inline new-inline" style="padding: 15px 0;">
                            <div id="progress-tracker" class="progress-tracker">
                                <div class="custom-progress-line"></div>
                                
                                <div class="step active" data-step="select-meals">
                                    <div class="step-marker"><span class="step-number">1</span></div>
                                    <div class="step-label">Select Meals</div>
                                </div>
                                <div class="step" data-step="checkout">
                                    <div class="step-marker"><span class="step-number">2</span></div>
                                    <div class="step-label">Checkout</div>
                                </div>
                            </div>
                            <div class="final-btn">
                                <a v-if="plan_quantity === 0" href="javascript:void(0);" class="continue-final" @click="gotoCheckout" style="">Continue to Checkout</a>
                                <template v-else-if="plan_quantity >= 0">
                                    <a href="javascript:void(0);" class="continue-progress" disabled="">
                                        <span class="updatecountonsidebar_remain">${{ (plan_quantity).toFixed(2) }}</span> away to continue
                                    </a>
                                </template>
                                <template v-else>
                                    <a href="javascript:void(0);" class="continue-final" @click="gotoCheckout" style="">Continue to Checkout</a>
                                    <p>Total: ${{ (adjustedQuantity).toFixed(2)  }}</p>
                                </template>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modalgif" style="display: none;"></div>
        <div class="body-content">
            <section class="pt-40 pb-40 sm-pt-4">
                <div class="container">
                    <div class="menu-heading text-center ml-16">
                                <h1
                                style="
                                border-bottom: 3px solid #40c656;
                                display: inline-block;
                                "
                                >À LA CARTE</h1>
                                <p
                                
                                >Choose your favorite proteins, carbs and veggies ($85 minimum)</p>
                                <!-- <p
                                style="
                                    color: #9b9b9b;
                                    max-width: 700px;
                                    font-size: 16px;
                                    margin: 0 auto;
                                    font-weight: 400;
                                "
                                class="para-by-the-lb">Meal Prep Boxes allow you to customize your meals by choosing from our menu of proteins, carbs, and veggies by the pound. Customize your prepared meals based on calories, carb counts, proteins, and fats. You’re in control with Meal Prep Boxes.</p> -->
                            </div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 cate_flex_row">
                           

                            <!-- Product Categories -->

                            <div class="shop-left-menu mb-5"
                            style="
                                margin-top: 85px;
                                margin-right: 1px;
                            "
                            >
                                <div class="product-category mb-4">
                                    <h4
                                    style="
                                        font-size: 17px;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                    "
                                    >
                                    <!-- <span class="desk-text">Product Categories</span><span class="mob-text">Product
                                            Categories</span><span class="accordion-header for-mobile-tite-only"
                                            id="headingY">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseG"
                                                aria-expanded="false" aria-controls="collapseOne"></button></span> -->
                                    </h4>
                                </div>
                                <div class="collapse show" aria-labelledby="headingY">
                                    
                                        <div v-for="tag in globalState.uniqueTags" :key="tag.name" class="form-check">
                                           
                                            <label
                                            style="
                                                color: #000;
                                                text-decoration: none;
                                                font-size: 14px;
                                            "
                                             class="form-check-label" :for="tag.name">{{ tag.name }}</label>
                                            <span style="color: #999;" class="list-count-right">({{ tag.appears }})</span>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
                            <div class="menu-list-details">
                                <div class="row" id="prdt">
                                    <!-- Meals and Popup -->
                                    <!-- <div class="text-center flavors-heading mb-5">
                                        <h3
                                        style="
                                        padding: 0 0 5px;
                                        width: 90%;
                                        border-bottom: 3px solid #40c656;
                                        margin: 0 auto;
                                        text-transform: uppercase;
                                        font-size: 30px;
                                        font-weight: 500;
                                        color: #171c1d;
                                        "
                                        >{{ tag }}</h3>
                                    </div> -->
                                        <MealPrepMeal v-for="product in products" :key="product._id" :product="product"/>
                                        <Popup />
                                        <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="updatecountonsidebar_main meals" style="display: none;">
            <div class="updatecountonsidebar_img">
                <img width="374" height="374"
                    src="https://www.flexpromeals.com/wp-content/themes/flexpro/images/big-carticon.png">
            </div>
            <div class="updatecountonsidebar_copy">
                <div class="copy_one"><span class="updatecountonsidebar digit">21</span> out of <span
                        class="digit">21</span> meals added</div>
                <div class="copy_two" style="display: none;">add <span class="updatecountonsidebar_remain">0</span> more
                    to
                    checkout</div>
                <div class="copy_two_part" style="display: block;">
                    <a id="sideopenhrefredirect" data-checkout="true" class="sc-fzpjYC kEAxug">Proceed to Checkout</a>
                </div>
                <div class="copy_two_part" style="display: block;">
                    <span class="copy_two_part_one">Still deciding?</span><span class="copy_two_part_two">Keep editing
                        →</span>
                </div>
            </div>
        </div>

    </body>

    </html>
</template>

<style scoped>
    @import './meals.css';
</style>

<script>
    import { reactive, ref, onMounted } from 'vue';
    import { globalStateCarte } from './MealPrepMeal.vue';
    import { getProducts } from './api.js';
    import { getPrice } from './api.js';
    import MealPrepMeal from './MealPrepMeal.vue'
    import Popup from './Popup.vue'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

    export default {
        name: 'PrepMenu',
        components: {
            MealPrepMeal,
            Popup,
            PulseLoader
        },
        data: function () {
            return {
                quantity: 0,
                plan_quantity: 85,
                adjustedQuantity: 0,
                color: '#40c656',
                color1: '#40c656',
                size: '45px',
                margin: '2px',
                radius: '2px'
            }
        },
        mounted() {
            document.title = this.$route.meta.title || 'Default Title';
            const script = document.createElement('script');
            script.src = 'https://www.flexpromeals.com/wp-content/themes/flexpro/js/bootstrap.min.js';
            script.async = true;
            document.body.appendChild(script);
        },
        setup() {
            console.log('Fetching Products in Setup...');
            const products = ref(null);
            const loading = ref(false);
            const error = ref(null);
            const response = ref(null);
            const selectedCategories = ref([]);
            const locationId = 'hrCAxvZl4GT8uGeT9GWF'
            const globalState = reactive({
                categorizedProducts: {},
                uniqueTags: [],
            });

            const fetchProducts = async () => {
                loading.value = true;
                try {
                    response.value = await getProducts(locationId); // Pass locationId as an argument
                    products.value = response.value.data.products.filter(product => product.description.includes('A la carte'));

                    // Function to fetch and set price for each product
                    const fetchAndSetProductPrices = async () => {
                        for (const product of products.value) {
                            try {
                                const priceResponse = await getPrice(product.locationId, product._id);
                                const amount = priceResponse.data.prices[0].amount;
                                product.price = parseFloat(amount).toFixed(2);
                            } catch (error) {
                                console.error('Failed to fetch product price for product:', product, error);
                                product.price = null;
                            }
                        }
                    };

                    // Call the function to fetch and set prices
                    await fetchAndSetProductPrices();


                    error.value = null;
                } catch (err) {
                    console.error('Error:', err);
                    error.value = 'Failed to fetch products.';
                } finally {
                    loading.value = false;
                }
            };

            const handleCheckboxChange = (event, category) => {
                const isChecked = event.target.checked;
                if (isChecked) {
                    selectedCategories.value.push(category);
                } else {
                    selectedCategories.value = selectedCategories.value.filter((c) => c !== category);
                }
                handleChange(selectedCategories.value);
            };

            const handleChange = (categories) => {
                // Check if categories array is empty
                if (categories.length === 0) {
                    products.value = response.value.data.products;
                } else {
                    // Filter products by categories
                    products.value = response.value.data.products;
                    products.value = products.value.filter((product) => {
                        // Check if any of the selected categories is in the product description
                        return categories.some((category) => product.description.includes(category));
                    });
                }
            };

            onMounted(fetchProducts);
            return { products, loading, error, globalState, selectedCategories, handleCheckboxChange };
        },
        created(){
            this.emitter.on('price-quantity-event-add', () => {
                this.plan_quantity = this.plan_quantity - globalStateCarte.price;
                this.adjustedQuantity  = this.adjustedQuantity + globalStateCarte.price
            }),
            this.emitter.on('price-quantity-event-sub', () => {
                this.plan_quantity = this.plan_quantity + globalStateCarte.price;
                this.adjustedQuantity  = this.adjustedQuantity - globalStateCarte.price
            })

        },
        
        methods: {
            gotoCheckout() {
                this.$router.push({ path: '/checkout' })
                globalStateCarte.totalAmount = this.adjustedQuantity || this.plan_quantity
            }
        }
    };
</script>
<template>
    <html>
   <section class="menu-plans"
        data-id="3127263" data-element_type="section">
        <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c4dfe1e"
                data-id="c4dfe1e" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-f4c94e0 elementor-widget elementor-widget-shortcode"
                        data-id="f4c94e0" data-element_type="widget" data-widget_type="shortcode.default">
                        <div class="elementor-widget-container">
                            <div class="elementor-shortcode">
                                <section class="vip">
                                    <div class="container">
                                        <div class="row">
                                            <div class="text-center mb-5 mt-5 w-700 mx-auto"></div>
                                            <div class="flex-vip-proplan text-center align-items-center mb-5"
                                                style="display:none; margin-top:-40px;">
                                                
                                            </div>
                                            <div class="vip_pro_plan_main text-center w-700 mx-auto">
                                                <div class="text-center mb-5 w-700 mx-auto">
                                                    <form method="post" action="#">
                                                        <div
                                                            class="d-flex  justify-content-between align-items-center my-4 font-weight">
                                                            <h4>Portion Size</h4>
                                                            <ul class="meal-size large-width">
                                                                <li>
                                                                    <input type="radio" name="meal-size" class="meal-size-selection" id="regular-meal-size" value="Regular" v-model="selectedSize" checked />
                                                                    <label for="regular-meal-size">Regular</label>
                                                                </li>
                                                                <li>
                                                                    <input type="radio" name="meal-size" class="meal-size-selection" id="large-meal-size" value="Large" v-model="selectedSize" />
                                                                    <label for="large-meal-size">Large</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="d-flex  justify-content-between align-items-center my-4 font-weight">
                                                            <h4>Meals Per Week</h4>
                                                            <ul class="meal-size small-width">
                                                                <li>
                                                                    <input type="radio" name="meal-per-box"
                                                                        class="meal-per-box-selection" id="5"
                                                                        value="5" checked v-model="selectedQuantity"/>
                                                                    <label for="Five">5</label>
                                                                </li>
                                                                <li>
                                                                    <input type="radio" name="meal-per-box"
                                                                        class="meal-per-box-selection" id="10"
                                                                        value="10" v-model="selectedQuantity"/>
                                                                    <label for="Ten">10</label>
                                                                </li>
                                                                <li>
                                                                    <input type="radio" name="meal-per-box"
                                                                        class="meal-per-box-selection" id="15"
                                                                        value="15" v-model="selectedQuantity"/>
                                                                    <label for="Fifteen">15</label>
                                                                </li>
                                                                <li>
                                                                    <input type="radio" name="meal-per-box"
                                                                        class="meal-per-box-selection" id="20"
                                                                        value="20" v-model="selectedQuantity"/>
                                                                    <label for="Twenty">20</label>
                                                                </li>
                                                                <li>
                                                                    <input type="radio" name="meal-per-box"
                                                                        class="meal-per-box-selection" id="25"
                                                                        value="25" v-model="selectedQuantity"/>
                                                                    <label for="TwentyFive">25</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>
                                                </div>
                                                    <div class="w-700 mx-auto flex_light_yellow">
                                                        <div class="border-bot">
                                                            <h5 class="mb-2 fw-bold">
                                                            <span class="meal-per-box-count">{{ quantity }}</span> <span class="meal-size-count">{{ type }}</span> {{ title }}
                                                            </h5>
                                                            <p>
                                                            <span class="portion">{{ description }}</span>
                                                            </p>
                                                            <div class="flexy border-top">
                                                            <p class="fw-bold">Price per meal <span class="flex-discounted">Discounted</span></p>
                                                            <p class="price ms-auto">${{ totalPrice }}</p>
                                                            <strike class="flex-strike">$rr{{ originalPrice }}</strike>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div class="w-700 mx-auto">
                                                    <div class="meal-label mealpro-checkout">
                                                        <form action="/meals" method="post" class="flex-vip-button"
                                                            id="flex-vip-form">
                                                            <button @click="gotoMeals" class="btn select_meals">Select Meals</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center mb-5 mt-5 w-700 mx-auto"></div>
                                            <div class="text-center mb-5 mt-5 w-700 mx-auto"></div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterPage/>
    </html>
</template>

<script>
import { reactive } from 'vue';
import FooterPage from '../shared/footer.vue'
export const globalPlans = reactive({
        quantitySelected: 0,
        mealType: '',
        price: 0.0,
        subscriptionId: null,
    });
    
export default {
  name: 'MenuDescription',
  components: {
            FooterPage, 
        },
  data() {
    return {
      selectedSize: 'Regular',
      selectedQuantity: '5',
      baseURL: process.env.VUE_APP_BASE_URL,
      meal_details: {
        Regular: { price: '$1.4', originalPrice: '$1.5', type: 'Regular', title: 'Meals / Per Week', description: 'Regular Portions.' },
        Large: { price: '$2.00', originalPrice: '$2.10', type: 'Large', title: 'Meals / Per Week', description: 'Larger Portions. 50% More Protein' }
      },
      quantities: [
        { value: '5', label: '5', regular_price: '11.50', large_price: '14.50' },
        { value: '10', label: '10', regular_price: '10.50', large_price: '13.50'},
        { value: '15', label: '15', regular_price: '10.00', large_price: '13.00' },
        { value: '20', label: '20', regular_price: '9.50', large_price: '12.50' },
        { value: '25', label: '25', regular_price: '9.00', large_price: '12.00' }
      ]
    };
  },
  mounted() {
    console.log('Base URL:', this.baseURL);
  },
  computed: {
    totalPrice() {
        // const quantity = parseInt(this.selectedQuantity);
        let pricePerMeal = 0;
        
        if (this.selectedSize === 'Regular') {
            pricePerMeal = parseFloat(this.quantities.find(q => q.value === this.selectedQuantity).regular_price);
        } else if (this.selectedSize === 'Large') {
            pricePerMeal = parseFloat(this.quantities.find(q => q.value === this.selectedQuantity).large_price);
        }
        globalPlans.price = (pricePerMeal).toFixed(2)
        return (pricePerMeal).toFixed(2);
    },
    originalPrice() {
      return this.meal_details[this.selectedSize]?.originalPrice;
    },
    type() {
        return this.meal_details[this.selectedSize].type;
    },
    title() {
        return this.meal_details[this.selectedSize].title;
    },
    description() {
        return this.meal_details[this.selectedSize].description;
    }, quantity() {
        return parseInt(this.selectedQuantity)
    }
  },
  methods: {
    gotoMeals() {
        globalPlans.mealType = this.selectedSize;
        globalPlans.quantitySelected = this.selectedQuantity;
        globalPlans.subscriptionId = this.$route.query.subscriptionId;

        this.$router.push({
            path: '/meals',
        })
    }
  }
};
</script>